import React from "react";
import { useState } from "react";
import { useMemo } from "react";
import { RequestMethod } from "../_utils";
import { useClient } from "./authContext";

const ProductContext = React.createContext();
ProductContext.displayName = "ProductsContext";

function ProductProvider(props) {
    const client = useClient();

    const [products, setProducts] = useState(null);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(12);
    const [maxItems, setMaxItems] = useState(1);
    const [maxPage, setMaxPage] = useState();
    const [groupName, setGroupName] = useState("Folia grzewcza");
    const [width, setWidth] = useState();
    const [power, setPower] = useState(null);
    const [currentCurrency, setCurrentCurrency] = useState();
    const [currencies, setCurrencies] = useState();
    const [showLoader, setShowLoader] = useState(false);
    const [showNoData, setShowNoData] = useState(false);
    const [searchString, setSearchString] = useState("");
    const [filters, setFilters] = useState([]);

    // React.useEffect(() => {
    //     const currenc = getCurrencies();
    //     run(currenc);
    // }, []);

    // React.useEffect(() => {
    //     const dataLoadingCallback = searchString ? getProductByName(searchString) : getProducts({groupName});
    //     run(dataLoadingCallback);
    // }, [page]);

    // React.useEffect(() => {
    //     setShowLoader(true);
    //     setShowNoData(false);
    //     if (groupName) {
    //         setPage(1);
    //         setProducts([]);

    //         const dataLoadingCallback = getProducts({groupName,curr : currencies?.find((x) => x.id == currentCurrency)?.symbol});
    //         run(dataLoadingCallback);
    //     }
    // }, [groupName, currentCurrency]);

    // React.useEffect(() => {
    //     setPage(1);
    // }, [pageSize]);

    const getProducts = React.useCallback(
        (props) => {
            let url = `Product/GetAllProducts`;

            if (props?.filter) setFilters(props.filter);

            setShowLoader(true);
            return client(url, RequestMethod.POST, {
                data: {
                    pageSize: pageSize,
                    page: props?.page || page,
                    groupName: props?.groupName || groupName,
                    currencyCode: props?.curr ?? "pln",
                    filters: props?.filter || filters,
                },
            })
                .then((data) => {
                    setShowNoData(false);
                    setProducts((list) => (list.length == 0 ? data?.listOfProducts : list ? list.concat(data?.listOfProducts) : data?.listOfProducts));
                    setMaxItems(data.productCount);
                    setMaxPage(Math.ceil(data.productCount / pageSize));
                    setShowLoader(false);
                    return data.listOfProducts;
                })
                .catch((error) => {
                    setProducts([]);
                    console.log(error);
                });
        },
        [page]
    );

    const getProductByName = React.useCallback((name) =>
        client(`Product/SearchProducts?query=${name}&page=${page}`, RequestMethod.GET)
            .then((data) => {
                setProducts((list) => (page == 1 ? data : list ? list.concat(data) : data));
                if (data.length === 0) {
                    setShowNoData(true);
                } else {
                    setShowNoData(false);
                }
                setShowLoader(false);
                return data;
            })
            .catch((error) => {
                setProducts([]);
                setShowLoader(false);
                console.log(error);
            })
    );

    const setCurrency = React.useCallback((curr) => {
        setCurrentCurrency(curr);
    });

    const getPictureOfProducts = (payload) =>
        client(`Product/GetPictureOfProductList`, RequestMethod.POST, { data: payload })
            .then((data) => {
                setProducts(products.map((x) => ({ ...x, Picture: data.find((y) => y.AsortymentId == x.Id) })));
            })
            .catch((error) => {
                console.log(error);
            });

    const getCurrencies = React.useCallback(() =>
        client(`Country/GetCurrencies`, RequestMethod.GET)
            .then((data) => {
                setCurrencies(data);
            })
            .catch(() => {})
    );

    const value = useMemo(
        () => ({
            products,
            page,
            setPage,
            pageSize,
            setPageSize,
            maxItems,
            maxPage,
            setGroupName,
            setPower,
            setWidth,
            getProductByName,
            setProducts,
            getProducts,
            showLoader,
            setShowLoader,
            showNoData,
            setShowNoData,
            currentCurrency,
            setCurrency,
            currencies,
            searchString,
            setSearchString,
            setFilters,

            filters,
        }),
        [
            products,
            page,
            setPage,
            pageSize,
            setPageSize,
            maxItems,
            maxPage,
            setGroupName,
            setPower,
            setWidth,
            getProductByName,
            setProducts,
            getProducts,
            showLoader,
            setShowLoader,
            showNoData,
            setShowNoData,
            currentCurrency,
            setCurrency,
            currencies,
            searchString,
            setSearchString,
            setFilters,

            filters,
        ]
    );

    return <ProductContext.Provider value={value} {...props} />;
}

function useProducts() {
    const context = React.useContext(ProductContext);
    if (context === undefined) {
        throw new Error(`useProducts must be used within a ProductContext`);
    }
    return context;
}

export { ProductProvider, useProducts };
