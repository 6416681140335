import i18n from "i18next";
import k from "../i18n/keys";

const UserRoleEnum = {
    Manufacturer: 1,
    Distributor: 2,
    Employee: 3,
};

const HousePartEnum = {
    Floor: 1,
    Ceiling: 2,
    Wall: 3,
};

const SystemTypeEnum = {
    FoilLaminatedPanel: 0,
    FoilVinylPanel: 1,
    FoilSpout: 2,
    FoilTiles: 3,
    FoilCeilling: 4,
    FoilWall: 5,
    HeatingMat: 6,
    InfraredHeater: 7,
    FoilWoodBoard: 8,
};
const systemTypeNames = {
    0: "Folia grzewcza pod panele laminowane",
    1: "Folia grzewcza pod panele winylowe SPC",
    2: "Folia grzewcza pod wylewkę",
    3: "Folia grzewcza pod płytki",
    4: "Folia grzewcza na sufit",
    5: "Folia grzewcza na ściany",
    6: "Mata grzewcza pod płytki",
    7: "Grzejnik na podczerwień",
    8: "Folia grzewcza pod deskę warstwową",
};

const SystemTypeRecommendation = {
    0: 0,
    1: 2,
    2: 1,
    3: 1,
    4: 1,
    5: 1,
    6: 1,
    7: 1,
    8: 1,
};

const ProductTypeEnum = {
    Measure: 1,
    Layer: 2,
    Plug: 3,
    Tape: 4,
};

const LayerTypeEnum = {
    RoomArea: 0,
    OffArea: 1,
    VaporFoil2m: 2,
    IsolatingGroundwork: 3,
    HeatFoil: 4,
    Termostat: 5,
    Wire: 6,
    Connector: 7,
    SelfvulcanizingTape: 8,
    HeatDecorTape: 9,
    ReinforcedTape: 10,
    Calibration: 11,
    VaporFoil4m: 12,
    SteelMesh: 13,
    GroundWire: 14,
    Measure: 15,
    Heater: 16,
    HeatMat: 17,
    InstallationBox: 18,
    ProtectivePipe: 19,
    ZigbeeControlPanel: 20,
    AirSensor: 21,
    InsulatingTape: 22,
};

const UnitsEnum = {
    SquareM: 0,
    Piece: 1,
    RunningM: 2,
};

const RequestMethod = {
    GET: "GET",
    POST: "POST",
    PUT: "PUT",
    DELETE: "DELETE",
};

const SectionTypeEnum = {
    Text: 0,
    Image: 1,
};

const KnowledgeRatingEnum = {
    None: 0,
    Yes: 1,
    No: 2,
};

const ProjectStatusEnum = {
    Sketch: 1,
    DoneWithoutInstallation: 2,
    DoneWithInstallation: 3,
};

const MessageType = {
    In: 0,
    Out: 1,
};

const PaymentPlatformEnum = {
    Traditional: 1,
    PayU: 2,
    TPay: 3,
    Przelewy24: 4,
    PayPal: 5,
};

const unitText = (unit) => {
    switch (unit) {
        case UnitsEnum.SquareM:
            return i18n.t(k.SQUAREM);
        case UnitsEnum.Piece:
            return i18n.t(k.PIECE);
        case UnitsEnum.RunningM:
            return i18n.t(k.RUNNINGM);
        default:
            return "";
    }
};

const energyClassEnum = {
    WT2021: 0,
    ZeroEnergy: 1,
    PassiveAPlus: 2,
    LowEnergy: 3,
    EnergySaving: 4,
    MediumEnergySaving: 5,
    Own: 6,
};

const energyClassList = [
    { Name: energyClassEnum.WT2021, Value: 15 },
    { Name: energyClassEnum.ZeroEnergy, Value: 25 },
    { Name: energyClassEnum.PassiveAPlus, Value: 40 },
    { Name: energyClassEnum.LowEnergy, Value: 45 },
    { Name: energyClassEnum.EnergySaving, Value: 80 },
    { Name: energyClassEnum.MediumEnergySaving, Value: 100 },
    { Name: energyClassEnum.Own, Value: 0 },
];

const energyClassText = (energyClass) => {
    switch (energyClass) {
        case energyClassEnum.WT2021:
            return i18n.t(k.WT2021);
        case energyClassEnum.ZeroEnergy:
            return i18n.t(k.ZEROENERGY);
        case energyClassEnum.PassiveAPlus:
            return i18n.t(k.PASSIVE_A_PLUS);
        case energyClassEnum.LowEnergy:
            return i18n.t(k.LOWENERGY);
        case energyClassEnum.EnergySaving:
            return i18n.t(k.ENERGYSAVING);
        case energyClassEnum.MediumEnergySaving:
            return i18n.t(k.MEDIUMENERGYSAVING);
        case energyClassEnum.Own:
            return i18n.t(k.OWN);
        default:
            return "";
    }
};

const installationRule = (rule) => {
    switch (rule) {
        case "PanelSystemEPL":
            return {
                firstName: i18n.t(k.FOIL) + " HD-EPL",
                secondName: i18n.t(k.SYSTEM_FOR_PANELS_5_MM_BASE),
            };
        case "PanelSystemG":
            return {
                firstName: i18n.t(k.FOIL) + " HD-G",
                secondName: i18n.t(k.SYSTEM_FOR_PANELS_5_MM_BASE),
            };
        case "PanelSystemPRO":
            return {
                firstName: i18n.t(k.FOIL) + " HD-PRO",
                secondName: i18n.t(k.SYSTEM_FOR_PANELS_5_MM_BASE),
            };
        case "SpoutSystemEPL":
            return {
                firstName: i18n.t(k.FOIL) + " HD-EPL",
                secondName: i18n.t(k.SYSTEM_UNDER_THE_SPOUT),
            };
        case "TileSystemMatt":
            return {
                firstName: i18n.t(k.HEATING_MAT) + " HD-MAT",
                secondName: i18n.t(k.SYSTEM_FOR_TILES),
            };
        case "XPSPanelSystemEPL":
            return {
                firstName: i18n.t(k.FOIL) + " HD-EPL",
                secondName: i18n.t(k.SYSTEM_FOR_PANELS_6_MM_XPS_UNDERLAY),
            };
        case "XPSPanelSystemG":
            return {
                firstName: i18n.t(k.FOIL) + " HD-G",
                secondName: i18n.t(k.SYSTEM_FOR_PANELS_6_MM_XPS_UNDERLAY),
            };
        case "XPSPanelSystemPRO":
            return {
                firstName: i18n.t(k.FOIL) + " HD-PRO",
                secondName: i18n.t(k.SYSTEM_FOR_PANELS_6_MM_XPS_UNDERLAY),
            };
        case "WoodBoardSystemEPL":
            return {
                firstName: i18n.t(k.FOIL) + " HD-EPL",
                secondName: "System pod deskę warstwową",
            };
        case "WoodBoardSystemG":
            return {
                firstName: i18n.t(k.FOIL) + " HD-G",
                secondName: "System pod deskę warstwową",
            };
        case "WoodBoardSystemPRO":
            return {
                firstName: i18n.t(k.FOIL) + " HD-PRO",
                secondName: "System pod deskę warstwową",
            };
        default:
            return "";
    }
};

const offAreaTypeList = () => {
    return [
        { Name: i18n.t(k.FURNITURE), Value: 0, foilDistance: 5 },
        { Name: i18n.t(k.SHOWER_BATH__WASHING_MACHINE), Value: 1, foilDistance: 10 },
        { Name: i18n.t(k.FREESTANDING_WASHBASIN__FREESTANDING_BOWL), Value: 2, foilDistance: 10 },
        { Name: i18n.t(k.WALL_HUNG_WASHBASIN__HUNG_BOWL), Value: 3, foilDistance: 10 },
        { Name: i18n.t(k.SEWAGE_GRATE), Value: 4, foilDistance: 30 },
        { Name: i18n.t(k.FIREPLACE), Value: 5, foilDistance: 50 },
    ];
};
const projectLevelType = () => {
    return [
        { Name: i18n.t(k.BASEMENT), Value: 0 },
        { Name: i18n.t(k.GROUND_FLOOR), Value: 1 },
        { Name: i18n.t(k.FIRST_FLOOR), Value: 2 },
        { Name: i18n.t(k.ATTIC), Value: 3 },
        { Name: i18n.t(k.STOREY), Value: 4 },
        { Name: i18n.t(k.LEVEL), Value: 5 },
        { Name: i18n.t(k.ROOM), Value: 6 },
    ];
};

const roomTypeList = () => {
    return [
        { Name: i18n.t(k.ROOM), Value: 0, color: "#EDD059" },
        { Name: i18n.t(k.LIVINGROOM), Value: 1, color: "#EDD059" },
        { Name: i18n.t(k.KITCHEN), Value: 2, color: "#77d755" },
        { Name: i18n.t(k.VESTIBULE), Value: 3, color: "#3f5787" },
        { Name: i18n.t(k.BEDROOM), Value: 4, color: "#967d2e" },
        { Name: i18n.t(k.BATHROOM), Value: 5, color: "#3385ff", onlyOuter: true },
        { Name: i18n.t(k.DININGROOM), Value: 6, color: "#77d755" },
        { Name: i18n.t(k.WARDROBE), Value: 7, color: "#967d2e" },
        { Name: i18n.t(k.TOILET), Value: 8, color: "#3385ff"},
        { Name: i18n.t(k.LAUNDRY), Value: 9, color: "#3385ff" },
        { Name: i18n.t(k.HALL), Value: 10, color: "#ffd52d" },
        { Name: i18n.t(k.CABINET), Value: 11, color: "#3f5787" },
        { Name: i18n.t(k.ANTEROOM), Value: 12, color: "#ffd52d" },
        { Name: i18n.t(k.CORRIDOR), Value: 13, color: "#ffd52d" },
        { Name: i18n.t(k.PANTRY), Value: 14, color: "#77d755" },
        { Name: i18n.t(k.LIVING_DINING_ROOM), Value: 15, color: "#EDD059" },
        { Name: i18n.t(k.LIVINGROOM_WITH_KITCHENETTE), Value: 16, color: "#EDD059" },
        { Name: i18n.t(k.GARAGE), Value: 17, color: "#9e5fcd" },
        { Name: i18n.t(k.TECHNICALROOM), Value: 18, color: "#9e5fcd" },
        { Name: i18n.t(k.UTILITYROOM), Value: 19, color: "#9e5fcd" },
        { Name: i18n.t(k.RECREATIONROOM), Value: 20, color: "#3f5787" },
        { Name: i18n.t(k.ENTRESOL), Value: 21, color: "#b58b6f" },
        { Name: i18n.t(k.SAUNA), Value: 22, color: "#3385ff", onlyOuter: true },
        { Name: i18n.t(k.BOILERROOM), Value: 23, color: "#9e5fcd" },
        { Name: i18n.t(k.CUBBY), Value: 24, color: "#ffd52d" },
        { Name: i18n.t(k.ATTIC), Value: 25, color: "#ffd52d" },
        { Name: i18n.t(k.STAIRS), Value: 26, color: "#ffd52d" },
        { Name: i18n.t(k.FIREPLACE_ROOM), Value: 27, color: "#3f5787" },
    ];
};

const FoilPosition = { optimal: 0, horizontal: 1, vertical: 2 };

const registrantTypeEnum = {
    Owner: 0,
    Manager: 1,
    Employee: 2,
};
const registrantType = () => {
    return [
        { Name: i18n.t(k.OWNER), Value: registrantTypeEnum.Owner },
        { Name: i18n.t(k.MANAGER), Value: registrantTypeEnum.Manager },
        { Name: i18n.t(k.EMPLOYEE), Value: registrantTypeEnum.Employee },
    ];
};

const companyTypeEnum = {
    INSTALLER: 0,
    WHOLESALER: 1,
    DEVELOPER: 2,
    CONSTRUCTION: 3,
    PHOTOVOLTAIC: 4,
    ARCHITECT: 5,
    OTHER: 6,
};

const companyType = () => {
    return [
        { Name: i18n.t(k.INSTALLER), Value: 0 },
        { Name: i18n.t(k.WHOLESALER), Value: 1 },
        { Name: i18n.t(k.DEVELOPER), Value: 2 },
        { Name: i18n.t(k.CONSTRUCTION), Value: 3 },
        { Name: i18n.t(k.PHOTOVOLTAIC), Value: 4 },
        { Name: i18n.t(k.ARCHITECT), Value: 5 },
        { Name: i18n.t(k.OTHER), Value: 6 },
    ];
};

const investmentTypeEnum = {
    HOUSE: 0,
    APARTMENT: 1,
    BLOCKOFFLATS: 2,
    HOUSINGESTATE: 3,
    WAREHOUSE: 4,
    PUBLICFACILITY: 5,
};

const investmentType = () => {
    return [
        { Name: i18n.t(k.HOUSE), Value: investmentTypeEnum.HOUSE },
        { Name: i18n.t(k.APARTMENT), Value: investmentTypeEnum.APARTMENT },
        { Name: i18n.t(k.BLOCKOFFLATS), Value: investmentTypeEnum.BLOCKOFFLATS },
        { Name: i18n.t(k.HOUSINGESTATE), Value: investmentTypeEnum.HOUSINGESTATE },
        { Name: i18n.t(k.WAREHOUSE), Value: investmentTypeEnum.WAREHOUSE },
        { Name: i18n.t(k.PUBLICFACILITY), Value: investmentTypeEnum.PUBLICFACILITY },
    ];
};

const matElementTypeEnum = {
    StraightLong: 0,
    StraightShortFull: 1,
    StraightShortHalf: 2,
    StraightStarter: 3,
    CurvedHalf: 4,
    CurvedFull: 5,
    StraightUnregular: 6,
};

const directionEnum = {
    LeftToRightUp: { x: 1, y: -1 },
    LeftToRightDown: { x: 1, y: 1 },
    RightToLeftUp: { x: -1, y: -1 },
    RightToLeftDown: { x: -1, y: 1 },
};
const matRoomPower = (productId) => {
    switch (productId) {
        case 101279:
            return 150;
        case 101278:
            return 225;
        case 101277:
            return 300;
        case 101276:
            return 375;
        case 101275:
            return 450;
        case 101274:
            return 525;
        case 101273:
            return 600;
        case 101029:
            return 675;
        case 101272:
            return 750;
        case 101271:
            return 900;
        case 101033:
            return 1050;
        case 101032:
            return 1200;
        case 101028:
            return 1350;
        case 101031:
            return 1500;
        case 101030:
            return 1800;
        default:
            return "";
    }
};

export {
    UserRoleEnum,
    HousePartEnum,
    SystemTypeEnum,
    ProductTypeEnum,
    LayerTypeEnum,
    UnitsEnum,
    RequestMethod,
    ProjectStatusEnum,
    SectionTypeEnum,
    KnowledgeRatingEnum,
    MessageType,
    unitText,
    PaymentPlatformEnum,
    energyClassList,
    energyClassEnum,
    energyClassText,
    offAreaTypeList,
    roomTypeList,
    FoilPosition,
    projectLevelType,
    registrantType,
    companyType,
    registrantTypeEnum,
    companyTypeEnum,
    investmentTypeEnum,
    investmentType,
    matElementTypeEnum,
    directionEnum,
    installationRule,
    matRoomPower,
    SystemTypeRecommendation,
    systemTypeNames,
};
