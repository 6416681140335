import React from "react";
import i18n from "i18next";
import k from "../i18n/keys";
import { Greenbox } from "./common";

const InfoUser = () => {
    return (
        <Greenbox>
            <div>!</div>
            <h2>{i18n.t(k.PRIMARY_USER)}</h2>
            <h5>{i18n.t(k.HAS_ACCESS_TO_ALL_WIZARDS_FUNCTIONALITIES)}</h5>
            <h5>{i18n.t(k.DEFINING_THEROLE_IN_THE_COMPANYS_STRUCTURE_WILL_FACILITATE_FURTHER_COOPERATION_WITH_HEAT_DECOR)}</h5>
            <h5>{i18n.t(k.SPECIFYING_THE_TYPE_OF_COMPANY_WILL_ALLOW_YOU_TO_ADJUST_THE_WIZARD_TO_YOUR_NEEDS)}</h5>
            <h5>{i18n.t(k.AFTER_REGISTRATION_HEAT_DECOR_WILL_CONTACT_THE_INDICATED_MAIN_USER)}</h5>

            <h2>{i18n.t(k.ADDITIONAL_USERS)}</h2>
            <h5>{i18n.t(k.THEY_CAN_USE_THE_WIZARD_TO_DEVELOP_DESIGNS_AND_QUOTES_FOR_THEIR_CLIENTS)}</h5>
            <h5>{i18n.t(k.THEY_HAVE_LIMITED_ACCOUNT_EDITING_CAPABILITIES)}</h5>
        </Greenbox>
    );
};
export { InfoUser };
