import React from "react";
import i18n from "i18next";
import k from "../i18n/keys";
import { Greenbox } from "./common";

const ContactForm = () => {
    return (
        <Greenbox>
            <div>!</div>
            <h2 style={{ marginTop: "0" }}>{i18n.t(k.IN_CASE_OF_REPEATED_DIFFICULTIES_WITH_USING_THE_WIZARD_CONTACT_THE_ADMINISTRATOR_OF_THE_HEAT_DECOR_WIZARD)}</h2>

            <ul>
                <li>
                    <p style={{ margin: "0" }}>{i18n.t(k.EMAIL)}</p>
                    <h3>it@heatdecor.com</h3>
                </li>
                <li>
                    <p style={{ margin: "0" }}>{i18n.t(k.TELEPHONE)}</p>
                    <h3>+48 000 000 000</h3>
                </li>
            </ul>
        </Greenbox>
    );
};

export { ContactForm };
