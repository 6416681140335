import React from "react";
import "./_styles/bootstrap";
import ReactDOM from "react-dom";
import { App } from "./App";
import { AppProviders } from "./_context";
import "./i18n/init";

ReactDOM.render(
  /*  <Profiler id="App Root" phases={['mount']}> */
  <AppProviders>
    <App />
  </AppProviders>,
  /*  </Profiler>, */
  document.getElementById("root")
);
