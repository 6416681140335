import React, { useCallback, useMemo, useState } from "react";

import { client } from "../_api";
import { RequestMethod } from "../_utils";
import { useClient } from "./authContext";

const CartContext = React.createContext();
CartContext.displayName = "CartContext";

function CartProvider(props) {
    const clientApi = useClient();

    const [cartProducts, setCartProducts] = useState([{ isProject: false, products: [] }]);
    const [cartPrice, setCartPrice] = useState(0);
    const [cartOffers, setCartOffers] = useState([]);
    const [unitPriceBasicBrutto, setUnitPriceBasicBrutto] = useState([]);
    const [unitPriceBasicNetto, setUnitPriceBasicNetto] = useState([]);
    const [totalPrice, setTotalPrice] = useState({ net: 0, gross: 0, profit: 0 });
    const [investmentOffersSum, setInvestmentOffersSum] = useState([]);
    const [sendToClient, setSendToClient] = useState(true);
    const [offersProductsNote, setOffersProductsNote] = useState("");
    const [totalDiscount, setTotalDiscount] = useState(0);
    const [cartData, SetCartData] = useState([]);
    const [currentCartTab, setCurrentCartTab] = useState(0);
    const [loader, setLoader] = useState(true);
    const [paymentPlatform, setPaymentPlatform] = useState(0);
    const [payGroupId, setPayGroupId] = useState(0);
    const [finalDocumentNumber, setFinalDocumentNumber] = useState("");
    const [clickedTab, setClickedTab] = useState();
    const [nextPage, setNextPage] = useState();
    const [order, setOrder] = useState();

    React.useEffect(() => {
        setLoader(true);
        sumTotalPrices();
        sumInvestmentOffers();
    }, [cartOffers]);

    const setDefaultCartPrice = (data) => {
        let totalGross = 0;
        for (let j = 0; j < data?.investments?.length; j++) {
            for (let i = 0; i < data?.investments[j].offers.length; i++) {
                totalGross = data?.investments[j].offers[i].bruttoPriceDistributor !== undefined ? totalGross + data?.investments[j].offers[i].bruttoPriceDistributor * 1 : 0;
            }
        }
        setCartPrice(totalGross.toFixed(2));
    };

    const sumTotalPrices = () => {
        let net = 0,
            gross = 0,
            profit = 0;
        if (cartOffers && cartOffers?.investments?.length > 0) {
            let orderedOffers = cartOffers?.investments?.filter((x) => x.cartOrder == true);
            for (let j = 0; j < orderedOffers.length; j++) {
                for (let i = 0; i < orderedOffers[j].offers.length; i++) {
                    net = orderedOffers[j].offers[i].nettoPriceDistributor !== undefined ? net + orderedOffers[j].offers[i].nettoPriceDistributor * 1 : 0;
                    gross = orderedOffers[j].offers[i].bruttoPriceDistributor !== undefined ? gross + orderedOffers[j].offers[i].bruttoPriceDistributor * 1 : 0;
                    profit = orderedOffers[j].offers[i].profit !== undefined ? profit + orderedOffers[j].offers[i].profit * 1 : 0;
                }
            }
        }
        setTotalPrice({ net: net, gross: gross, profit: profit });
    };

    const sumInvestmentOffers = () => {
        let investmentOffersPrices = [];
        let net = 0;
        let gross = 0;
        let profit = 0;
        if (cartOffers && cartOffers?.investments?.length > 0) {
            for (let i = 0; i < cartOffers?.investments?.length; i++) {
                net = 0;
                gross = 0;
                profit = 0;
                for (let j = 0; j < cartOffers?.investments[i]?.offers.length; j++) {
                    net = net + parseFloat(cartOffers?.investments[i]?.offers[j].nettoPriceBasic) * 1;
                    gross = gross + parseFloat(cartOffers?.investments[i]?.offers[j].bruttoPriceBasic) * 1;
                    profit = profit + parseFloat(cartOffers?.investments[i]?.offers[j].profit) * 1;
                }
                investmentOffersPrices.push({
                    net: net,
                    gross: gross,
                    profit: profit,
                });
            }
        }
        setInvestmentOffersSum(investmentOffersPrices);
        setLoader(false);
    };

    const setUnitPrice = (data) => {
        if (unitPriceBasicNetto.length == 0 || isNaN(unitPriceBasicNetto[0])) {
            let unitPriceBrutto = data?.map((product) => {
                return parseFloat((product.bruttoPriceBasic / product.amount).toFixed(2));
            });
            let unitPriceNetto = data?.map((product) => {
                return parseFloat((product.nettoPriceBasic / product.amount).toFixed(2));
            });

            setUnitPriceBasicBrutto(unitPriceBrutto);
            setUnitPriceBasicNetto(unitPriceNetto);
        }
    };

    const getCart = useCallback(
        () =>
            client("Cart/Get", RequestMethod.GET)
                .then((data) => {
                    if (data?.cart && data?.cart.length > 0) {
                        var prices = data.cart.map((x) => x.products.map((p) => p.price.toFixed(2) * (p.amount ?? 1)).reduce((x, y) => x + y, 0.0));
                        setCartPrice(prices.reduce((x, y) => x + y, 0.0).toFixed(2));
                        setCartProducts(data.cart);
                    }
                })
                .then(() => {}),
        []
    );

    const getNewPrice = React.useCallback((params) => {
        if (params.products.map((x) => x.products).flat().length > 0)
            return clientApi("Product/GetPriceForProducts", RequestMethod.POST, {
                data: {
                    productIds: params.products
                        .map((x) => x.products)
                        .flat()
                        .map((x) => x.id),
                    currencyCode: params.currency,
                },
            })
                .then((data) => {
                    var prodNotProj = params.products.find((x) => x.isProject == false)?.products.map((x) => ({ ...x, price: data.find((y) => y.id == x.id).price }));
                    var prodProj = null;
                    if (params.products.find((x) => x.isProject == true))
                        prodProj = params.products.find((x) => x.isProject == true)?.products.map((x) => ({ ...x, price: data.find((y) => y.id == x.id).price }));

                    var cart = [{ ...params.products.find((x) => x.isProject == false), products: prodNotProj }];
                    if (params.products.length > 1) cart.push({ ...params.products.find((x) => x.isProject == true), products: prodProj });

                    setCartProducts(cart);
                })
                .catch((e) => {});
        else return;
    }, []);

    const sumDiscount = (data) => {
        let sum = 0;
        data.investments.map((investment) => {
            investment.offers.map((offer) => {
                sum += offer.discount;
            });
        });
        setTotalDiscount(sum);
    };

    const getCartOrder = useCallback(
        () =>
            clientApi("Cart/GetCartOrder", RequestMethod.GET).then((data) => {
                if (data.bruttoPrice == 0) {
                    data.investments.map((item) => {
                        item.cartOrder = true;
                    });
                }
                setCartOffers(data);
                setDefaultCartPrice(data);
                setOffersProductsNote(data.notes);
                sumDiscount(data);
            }),
        []
    );

    const getProductsList = React.useCallback(
        (investmentDetailsList) =>
            clientApi("Cart/AddInvestmentOffers", RequestMethod.POST, {
                data: {
                    investmentDetailsList: investmentDetailsList,
                },
            })
                .then(() => {
                    getCartWithProducts();
                })
                .catch((e) => {
                    alert(e);
                }),
        []
    );

    const getCartWithProducts = useCallback(
        () =>
            clientApi("Cart/GetCartOrder", RequestMethod.GET).then((data) => {
                setUnitPrice(data.cartOrderProducts);
                setOffersProductsNote(data.notes);
                sumDiscount(data);
                data.investments.map((item) => {
                    item.cartOrder = true;
                });
                setCartOffers(data);
            }),
        []
    );

    const addCartOrderAddress = React.useCallback(
        (destinationAddress, investmentDetailsList) =>
            clientApi("Cart/AddInvestmentOffers", RequestMethod.POST, {
                data: {
                    investmentDetailsList: investmentDetailsList,
                    destinationAddress: parseInt(destinationAddress),
                },
            })
                .then(() => {
                    getCartWithProducts();
                })
                .catch((e) => {
                    alert(e);
                }),
        []
    );

    const removeInvestmentOffers = React.useCallback(
        (offerId) =>
            clientApi("Cart/RemoveInvestmentOffers", RequestMethod.POST, {
                data: {
                    offerId: offerId,
                },
            })
                .then(() => {
                    getCartOrder();
                })
                .catch((e) => {
                    alert(e);
                }),
        []
    );

    const removeInvestment = React.useCallback(
        (investmentId) =>
            clientApi("Cart/RemoveOrderedOffers", RequestMethod.POST, {
                data: {
                    investmentIdsList: investmentId,
                },
            })
                .then(() => {
                    getCartOrder();
                })
                .catch((e) => {}),
        []
    );

    const removeOrderedOffers = React.useCallback(
        (investmentIdsList, transactionPaymentUrl, paymentPlatform) =>
            clientApi("Cart/RemoveOrderedOffers", RequestMethod.POST, {
                data: {
                    investmentIdsList: investmentIdsList,
                },
            })
                .then(() => {
                    if (paymentPlatform == 1) {
                    } else if (paymentPlatform == 5) {
                        window.location.replace(window.location.origin + "/thanksfororder");
                    } else {
                        window.location.replace(transactionPaymentUrl);
                    }

                    setLoader(false);
                })
                .catch((e) => {}),
        []
    );

    const updateCartOrderInvestorAddress = React.useCallback((cartOrderDeliveryAddressId, address) =>
        clientApi("Cart/UpdateCartOrderInvestorAddress", RequestMethod.POST, {
            data: {
                cartOrderDeliveryAddressId: cartOrderDeliveryAddressId,
                newAddress: address,
            },
        })
            .then(() => {
                getCartOrder();
            })
            .catch((e) => {
                alert(e);
            })
    );

    const updateCartOrder = React.useCallback(
        (cart) =>
            clientApi("Cart/UpdateCartOrder", RequestMethod.POST, { data: cart })
                .then(() => {})

                .catch((e) => {
                    alert(e);
                }),
        []
    );

    const updateCartAndGetNewCart = React.useCallback(
        (cart) =>
            clientApi("Cart/UpdateCartOrder", RequestMethod.POST, { data: cart })
                .then(() => {
                    getCartOrder();
                })

                .catch((e) => {
                    alert(e);
                }),
        []
    );

    const updateClientProducts = React.useCallback(
        (result, offersIds, unitPriceBrutto, unitPriceNetto) =>
            clientApi("Cart/GetCartOrder", RequestMethod.GET)
                .then((data) => {
                    let newBruttoPrice = 0;
                    let newNettoPrice = 0;
                    let newProducts = data.cartOrderProducts.map((product, id) => {
                        if (offersIds?.includes(product.offerId)) {
                            newBruttoPrice += result.cartOrderProducts[id].bruttoPriceBasic;
                            newNettoPrice += result.cartOrderProducts[id].nettoPriceBasic;
                            return result.cartOrderProducts[id];
                        } else {
                            newBruttoPrice += unitPriceBrutto[id] * data.cartOrderProducts[id].amount;
                            newNettoPrice += unitPriceNetto[id] * data.cartOrderProducts[id].amount;
                            return data.cartOrderProducts[id];
                        }
                    });
                    let newData = { ...data };
                    newData.cartOrderProducts = newProducts;
                    newData.bruttoPrice = parseFloat(newBruttoPrice.toFixed(2));
                    newData.nettoPrice = parseFloat(newNettoPrice.toFixed(2));
                    updateCartAndGetNewCart(newData);
                })

                .catch((e) => {}),
        []
    );

    const removeOrderedInvestments = (data, transactionPaymentUrl, paymentPlatform) => {
        let cartInvestmentsIds = [];
        for (let i = 0; i < data.investments.length; i++) {
            cartInvestmentsIds.push(data.investments[i].id);
        }
        removeOrderedOffers(cartInvestmentsIds, transactionPaymentUrl, paymentPlatform);
    };

    const value = useMemo(
        () => ({
            cartData,
            SetCartData,
            cartProducts,
            setCartProducts,
            cartPrice,
            getNewPrice,
            totalPrice,
            setTotalPrice,
            cartOffers,
            setCartOffers,
            sendToClient,
            setSendToClient,
            offersProductsNote,
            setOffersProductsNote,
            getCart,
            getCartOrder,
            addCartOrderAddress,
            removeInvestmentOffers,
            updateCartOrder,
            updateCartOrderInvestorAddress,
            setUnitPriceBasicBrutto,
            setUnitPriceBasicNetto,
            unitPriceBasicBrutto,
            unitPriceBasicNetto,
            currentCartTab,
            setCurrentCartTab,
            loader,
            setLoader,
            investmentOffersSum,
            removeOrderedInvestments,
            paymentPlatform,
            setPaymentPlatform,
            payGroupId,
            setPayGroupId,
            removeInvestment,
            getProductsList,
            updateCartAndGetNewCart,
            totalDiscount,
            updateClientProducts,
            finalDocumentNumber,
            setFinalDocumentNumber,
            clickedTab,
            setClickedTab,
            nextPage,
            setNextPage,
            order,
            setOrder,
        }),
        [
            cartProducts,
            cartData,
            SetCartData,
            setCartProducts,
            cartPrice,
            getNewPrice,
            totalPrice,
            setTotalPrice,
            cartOffers,
            setCartOffers,
            sendToClient,
            setSendToClient,
            offersProductsNote,
            setOffersProductsNote,
            getCart,
            getCartOrder,
            addCartOrderAddress,
            removeInvestmentOffers,
            updateCartOrder,
            updateCartOrderInvestorAddress,
            setUnitPriceBasicBrutto,
            setUnitPriceBasicNetto,
            unitPriceBasicBrutto,
            unitPriceBasicNetto,
            currentCartTab,
            setCurrentCartTab,
            loader,
            setLoader,
            investmentOffersSum,
            removeOrderedInvestments,
            paymentPlatform,
            setPaymentPlatform,
            payGroupId,
            setPayGroupId,
            removeInvestment,
            getProductsList,
            updateCartAndGetNewCart,
            totalDiscount,
            updateClientProducts,
            finalDocumentNumber,
            setFinalDocumentNumber,
            clickedTab,
            setClickedTab,
            nextPage,
            setNextPage,
            order,
            setOrder,
        ]
    );

    return <CartContext.Provider value={value} {...props} />;
}

function useCart() {
    const context = React.useContext(CartContext);
    if (context === undefined) {
        throw new Error("useCart must be used within a CartContext");
    }
    return context;
}

export { useCart, CartProvider, CartContext };
