import React, { Fragment, useMemo } from "react";
import styled from "styled-components";
import { Link as RouterLink } from "react-router-dom";
import device from "../_styles/media-queries";

const CircleButton = styled.button`
    border-radius: 30px;
    padding: 0;
    width: 30px;
    height: 30px;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${({ theme: { colors }, primary }) => (primary ? colors.green.normal : colors.base)};
    color: ${({ theme: { colors }, primary }) => (primary ? colors.base : colors.gray.dark)};
    fill: ${({ theme: { colors }, primary }) => (primary ? "white" : colors.gray.dark)};
    img {
        filter: ${({ theme: { colors }, primary }) => (primary ? "brightness(0) invert(1)" : "none")};
    }
    cursor: pointer;
    border: ${({ theme: { colors }, primary }) => `1px solid ${primary ? colors.green.normal : colors.gray.dark}`};
    &:hover {
        background: ${({ theme: { colors }, primary }) => colors.green.normal};
        color: ${({ theme: { colors }, primary }) => (primary ? colors.gray.dark : colors.base)};
        border: ${({ theme: { colors }, primary }) => `1px solid ${colors.green.normal}`};
        fill: ${({ theme: { colors }, primary }) => (primary ? colors.white : "white")};
        > img {
            filter: brightness(0) invert(1);
        }
    }
`;

const RootButton = styled.button`
    color: ${({ theme: { colors }, primary }) => (primary ? colors.base : colors.green.normal)};
    cursor: inherit;
    border: none;
    background-color: transparent;
    border-radius: 5px;
    cursor: ${(props) => (props.to || props.onClick || props.type === "submit" ? "pointer" : "default")};
    &:hover {
        opacity: 0.8;
    }
    svg {
        font-size: 22px;
        margin: 0 5px 0 0;
    }
`;

const StyledRegularButton = styled(RootButton)`
    @media (max-width: 767px) {
        font-size: 10px;
    }
    background: ${({ theme: { colors }, primary }) => (primary ? colors.green.normal : colors.gray.light)};
    padding: 12px 15px;
    font-size: 15px;
    border: ${({ theme: { colors }, primary }) => (!primary ? `1px solid ${colors.green.dark}` : `none`)};
    letter-spacing: 1px;
    text-transform: uppercase;
    &:disabled {
        cursor: default;
        background: ${({ theme: { colors } }) => colors.gray.normal};
    }
`;

const StyledWarningButton = styled(StyledRegularButton)`
    background: ${({ theme: { colors }, primary }) => (-primary ? colors.orange.dark : colors.base)};
    border: ${({ theme: { colors }, primary }) => (!primary ? `1px solid ${colors.orange.dark}` : `none`)};
`;

const StyledInlineButton = styled(RootButton)`
    padding: 0;
    display: inline-flex;
    align-items: center;
    font-size: ${({ primary }) => (primary ? "16px" : "14px")};
    color: ${({ theme: { colors }, primary }) => (primary ? colors.black : colors.gray.dark)};
    &:hover {
        text-decoration: none;
        color: ${({ theme: { colors }, primary }) => (primary ? colors.green.normal : colors.black)};
    }
    svg {
        font-size: 18px;
        margin: 0px 0 0 4px;
    }
    &:disabled {
        cursor: default;
        color: ${({ theme: { colors } }) => colors.gray.normal};
    }
`;

const Link = styled(RouterLink)`
    color: ${({ theme: { colors }, primary }) => (primary ? colors.gray.dark : colors.green.normal)};
    &:hover {
        color: ${({ theme: { colors }, primary }) => (primary ? colors.green.normal : colors.base)};
        text-decoration: underline;
    }
`;

const LinkStyle = styled(RouterLink)`
    color: ${({ theme: { colors }, primary }) => (primary ? colors.gray.dark : colors.green.normal)};
    &:hover {
        color: ${({ theme: { colors }, primary }) => (primary ? colors.green.normal : colors.base)};
        text-decoration: underline;
    }
    @media ${device.laptopL} {
        width: 70%;
    }
`;

const Button = ({ primary, variant, icon, iconRight, children, ...props }) => {
    const { to } = props;
    const Component = useMemo(() => {
        switch (variant) {
            case "inline":
                return StyledInlineButton;
            case "regular":
                return StyledRegularButton;
            case "warning":
                return StyledWarningButton;
            case "inlineBoldGreen":
                return BoldInlineButton;
            case "inlineBoldBlack":
                return BoldInlineButtonBlack;
            default:
                return StyledRegularButton;
        }
    }, [variant]);
    const content = (
        <Component primary={primary} icon={icon} iconRight={iconRight} {...props}>
            {icon} {children} {iconRight}
        </Component>
    );

    return to ? <Link {...props}>{content}</Link> : <Fragment>{content}</Fragment>;
};

const LogoutButton = styled(Button)`
    padding: 0;
    width: max-content;
    border: none;
    text-transform: none;
    margin: 8px 0px 0px 0px;
    font-size: 14px;
    transition: all 0.5s ease;
    color: ${({ theme: { colors } }) => colors.gray.normal};
    &:hover {
        color: ${({ theme: { colors } }) => colors.gray.dark};
    }
    svg {
        margin: 0px 5px 2px 0;
    }
`;

const ActionButton = styled(Button)`
    border: none;
    text-transform: none;
    font-size: 14px;
    color: ${({ theme: { colors } }) => colors.gray.dark};
    &:hover {
        color: ${({ theme: { colors }, warning }) => (warning ? colors.orange.dark : colors.green.normal)};
    }
    &:disabled {
        background: none;
        color: ${({ theme: { colors } }) => colors.gray.normal};
    }
    svg {
        margin: 0px 0 2px 5px;
    }
`;

const ToggleButton = styled(Button)`
    position: absolute;
    top: ${({ toggle }) => (toggle ? "44px" : "25px")};
    // top: 25px;
    right: 25px;
    color: ${({ theme: { colors } }) => colors.gray.dark};
    svg {
        font-size: 20px;
    }
`;

const BoldInlineButton = styled(StyledInlineButton)`
    font-weight: bold;
    color: ${({ theme: { colors } }) => colors.green.normal};
    &:hover {
        color: ${({ theme: { colors } }) => colors.gray.dark};
    }
`;
const BoldInlineButtonBlack = styled(StyledInlineButton)`
    font-weight: bold;
    color: ${({ theme: { colors } }) => colors.black};
    &:hover {
        color: ${({ theme: { colors } }) => colors.gray.dark};
    }
`;

const ModalButtonsWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    button {
        margin: 5px;
    }
    @media only screen and ${device.maxLaptop} {
        flex-wrap: wrap !important;
        button {
            width: 100% !important;
        }
    }
    @media only screen and ${device.maxMobileL} {
    }
`;

const StyledButtonRadio = styled.div`
    label {
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        background: ${({ theme: { colors }, selected }) => (selected ? colors.green.normal : colors.base)};
        color: ${({ theme: { colors }, selected }) => (selected ? colors.base : colors.green.normal)};
        border: 1px solid ${({ theme: { colors } }) => colors.green.normal};
        // padding: 5px 10px;
        border-radius: 2px;
        margin: 0 2.5px;
        cursor: pointer;
        font-size: 16px;
        user-select: none;
        font-size: 14px;
    }
    input {
        opacity: 0;
        position: fixed;
        width: 0;
    }
    label:hover {
        background-color: ${({ theme: { colors }, selected }) => (selected ? colors.base : colors.green.normal)};
        color: ${({ theme: { colors }, selected }) => (selected ? colors.green.normal : colors.base)};
    }
    width: 25%;
    text-align: center;
`;

const ButtonRadio = ({ children, label, isSelected, name, handleOnClick, style, disabled = false }) => {
    return (
        <StyledButtonRadio
            selected={isSelected}
            onClick={disabled ? null : handleOnClick}
            style={{
                ...style,
                cursor: disabled ? "not-allowed" : "pointer",
                opacity: disabled ? 0.2 : 1,
            }}
            disabled={disabled}>
            <label> {label}</label>
            <input type="radio" name={name} disabled={disabled} />
        </StyledButtonRadio>
    );
};

export { Button, StyledRegularButton, Link, LinkStyle, CircleButton, LogoutButton, ToggleButton, ActionButton, ModalButtonsWrapper, ButtonRadio, StyledWarningButton };
