import React from "react";
import { useAuth } from "./_context/authContext";
import { FullPageSpinner } from "./_components";
import { ThemeProvider } from "styled-components";
import theme from "./_styles/theme";
import { CookiesProvider } from "react-cookie";
// import { getAppInsights, reactPlugin } from "./TelemetryService";
// import TelemetryProvider from "./telemetry-provider";
// import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";

const AuthenticatedApp = React.lazy(() => import("./AuthenticatedApp"));
const UnauthenticatedApp = React.lazy(() => import("./UnauthenticatedApp"));

function App() {
    const { user } = useAuth();
    // let appInsights = null;
    return (
        <ThemeProvider theme={theme}>
            <React.Suspense fallback={<FullPageSpinner />}>
                {/* <TelemetryProvider
                    instrumentationKey={process.env.REACT_APP_INSTRUMENTATION_KEY}
                    after={() => {
                        appInsights = getAppInsights();
                    }}>
                    <AppInsightsContext.Provider value={reactPlugin}> */}
                <CookiesProvider>{user ? <AuthenticatedApp /> : <UnauthenticatedApp />}</CookiesProvider>
                {/* </AppInsightsContext.Provider>
                </TelemetryProvider> */}
            </React.Suspense>
        </ThemeProvider>
    );
}

export { App };
