import React from "react";
import i18n from "i18next";
import k from "../i18n/keys";
import { Greenbox } from "./common";

const InfoHeatDecor = () => {
    return (
        <Greenbox>
            <div>?</div>

            <h1>
                {i18n.t(k.WHAT_WILL_YOU_GAIN_BY_WORKING)} {i18n.t(k.WITH_THE_HEAT_DECOR_CREATOR)}
            </h1>
            <p>
                {i18n.t(k.PRECISE_DESIGNS_OF_THE_HEATING_SYSTEM_IN_THE)} {i18n.t(k.HEAT_DECOR_STANDARD_READY_FOR_USE_BY)} {i18n.t(k.THE_INSTALLATION_TEAM)}
            </p>

            <p>
                {i18n.t(k.EASIER_AND_MORE_COMPLETE_PREPARATION_OF_AN_OFFER_FOR)} {i18n.t(k.YOUR_CLIENT)}
            </p>
            <p>
                {i18n.t(k.FACILITATING_THE_PROCESS_OF_ORDERING_PRODUCTS)} {i18n.t(k.HEAT_DECOR)}
            </p>
        </Greenbox>
    );
};

export { InfoHeatDecor };
