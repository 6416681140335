const k = require("./keys");

export const english = {
    [k._]: `:`,
    [k._1]: ``,
    [k._10]: `4`,
    [k._11]: `5`,
    [k._12]: `6`,
    [k._13]: `06:00`,
    [k._14]: `10`,
    [k._15]: `1:`,
    [k._16]: `]`,
    [k._17]: `100%`,
    [k._2]: `-`,
    [k._3]: `/`,
    [k._4]: `.`,
    [k._5]: `(`,
    [k._6]: `12:00`,
    [k._7]: `1`,
    [k._8]: `2`,
    [k._9]: `3`,
    [k.ADD]: `Add`,
    [k.ADD_ADDRESS]: `Add address`,
    [k.ADD_ANOTHER_ROOM]: `Add another room`,
    [k.ADD_NEW_ADDRESS]: `Add new address`,
    [k.ADDTOCART]: `Add to cart`,
    [k.ANNUAL_ELECTRICITY_CONSUMPTION]: `Roczne zużycie energii elektrycznej`,
    [k.ANNUAL_OPERATING_COSTS]: `Roczne koszty eksploatacyjne`,
    [k.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_ADDRESS]: `Are you sure you want to delete this address?`,
    [k.ARCHITECT]: `Architect`,
    [k.APARTMENT]: `Mieszkanie`,
    [k.BACK]: `Back`,
    [k.TRANSFER]: `Bank transfer`,
    [k.BANK_TRANSFER]: `Bank transfer`,
    [k.BLOCKOFFLATS]: `Block of flats`,
    [k.BUILDINGENERGYCLASS]: `Klasa energetyczna budynku`,
    [k.BUYBYMETER]: `Buy by the meter`,
    [k.BUYONROLLS]: `Buy on rolls`,
    [k.BYMETERS]: `(by meters)`,
    [k.CONFIRM]: `Confirm`,
    [k.CZECH]: `Czech`,
    [k.CALCULATECOSTS]: `Oblicz koszty`,
    [k.CALCULATE_HEATING_COSTS]: `Calculate heating costs`,
    [k.CREATE_ACCOUNT]: `Create an account`,
    [k.REMEMBER_ME]: `Remember me`,
    [k.CANCEL]: `Cancel`,
    [k.CHOOSELANG]: `Choose language`,
    [k.CHOOSE_NEXT_STEP]: `Choose next step`,
    [k.COSTCALCULATION]: `Kalkulacja kosztów`,
    [k.CONSTRUCTION]: `Construction`,
    [k.DELETE]: `Delete`,
    [k.DEVELOPER]: `Developer`,
    [k.DISCOUNTGRANTED]: `Discount granted`,
    [k.DISCOUNTLEVEL]: `Your current discount level`,
    [k.DISTRIBUTORS]: `Distributors`,
    [k.ELECTRICHEATERS]: `Electric heaters`,
    [k.EMPLOYEE]: `Employee`,
    [k.ENGLISH]: `English`,
    [k.ENTER_ROOMS_DIMENSIONS]: `Enter room's dimensions`,
    [k.ENTER_ROOMS_DIMENSIONS_BASED_ON_FIGURE]: `Enter room's dimensions based on figure`,
    [k.ENTER_A_NEW_PASSWORD]: `The new password should contain at least 8 characters, upper and lower case letters and a special character`,
    [k.FINISHED]: `Finished`,
    [k.FOILPOWER]: `Foil power`,
    [k.FOILWIDTH]: `Foil width`,
    [k.FRENCH]: `French`,
    [k.GERMAN]: `German`,
    [k.GROSS]: `gross`,
    [k.ELECRICITYPRICE]: `Cena prądu (PLN / kWh)`,
    [k.ENERGYCLASS]: `Klasa energetyczna budynku (kWh / m2 na rok)`,
    [k.ENTER_INSTALLATION_PARAMS]: `Podaj parametry instalacji aby obliczyć zużycie`,
    [k.FOR_CLIENT]: `For client`,
    [k.GENERAL_HEATING_COST_CALCULATOR]: `Ogólny kalkulator kosztów ogrzewania na podczerwień z klasą
  energetyczną budynku:`,
    [k.LOWENERGY]: `A low energy`,
    [k.GOTOCASHREGISTER]: `Przejdź do kasy`,
    [k.HEATINGCOSTCALCULATOR]: `Kalkulator kosztów ogrzewania`,
    [k.HEATINGFOIL]: `Heating foil`,
    [k.HEATINGMAT]: `Heating mat`,
    [k.HOUSE]: `House`,
    [k.HOUSINGESTATE]: `Housing estate`,
    [k.INSTALLER]: `Installer`,
    [k.I_ORDER_WITH_SHIPPING_TO_CUSTOMERS]: `I order with shipping to customers`,
    [k.I_AM_ORDERING_FOR_MY_OWN_WAREHOUSE]: `I am ordering for my own warehouse`,
    [k.ITALIAN]: `Italian`,
    [k.HEATING_SYSTEM_POWER]: `Moc znamionowa systemu grzewczego`,
    [k.INSTALLATIONDESIGN]: `Installation design`,
    [k.INVESTMENT_MATERIALS_COSTS]: `Koszty inwestycyjne materiałów`,
    [k.INVESTORS]: `Investors`,
    [k.INVESTMENTS]: `Investments`,
    [k.KNOWLEDGEBASE]: `Knowledge base`,
    [k.LARGEQUANTITYINSTOCK]: `Large quantity in stock`,
    [k.LEAVEPROJECT]: `Leave project`,
    [k.LENGTH_IN_CM]: `Length in cm`,
    [k.LOGIN]: `Login`,
    [k.LOG_IN]: `Log in`,
    [k.LOGOUT]: `Logout`,
    [k.MANAGER]: `Manager`,
    [k.MARKETING]: `Marketing`,
    [k.MEDIUMENERGYSAVING]: `C medium energysaving`,
    [k.ENERGYSAVING]: `B energysaving`,
    [k.MIN_PV_INSTALATION_REQUIRED]: `Wymagana minimalna instalacja fotowoltaiczna do
  zbilansowania kosztów ogrzewania`,
    [k.MORE_PRODUCTS_AND_GO_TO_HIGHER_DISCOUNT_LEVEL]: `more products and go to a higher discount level`,
    [k.NET]: `net`,
    [k.NEWROOM]: `New room`,
    [k.NO]: `No`,
    [k.NODATA]: `No data`,
    [k.NORWEGIAN]: `Norwegian`,
    [k.POLISH]: `Polish`,
    [k.MONTHLY_OPERATING_COSTS]: `Miesięczne koszty eksploatacyjne`,
    [k.OFFERGENERATING]: `Generowanie oferty`,
    [k.OFFERS]: `Offers`,
    [k.ORDERS]: `Orders`,
    [k.ONLINE_PAYMENT]: `Online payment`,
    [k.PAYMENT_METHOD]: `Payment method`,
    [k.ONROLLS]: `(on rolls)`,
    [k.OWN]: `Own`,
    [k.OWNER]: `Owner`,
    [k.PASSIVE_A_PLUS]: `A+ Passive`,
    [k.PHOTOVOLTAIC]: `Photovoltaic`,
    [k.PRESENTED_CALC_ARE_INFORMATIVE]: `*Przedstawione obliczenia mają charakter informacyjny i nie stanowią
  oferty handlowej w rozumieniu Art. 66.par.1 Kodeksu Cywilnego`,
    [k.PRICELIST]: `Price list`,
    [k.PRODUCT]: `Product`,
    [k.PRODUCTSINCART]: `Products in cart`,
    [k.PRODUCTAVAILABLE]: `Product available`,
    [k.PROFIT]: `Profit`,
    [k.PROJECTS]: `Projects`,
    [k.PUBLICFACILITY]: `Public facility`,
    [k.RATED_ENERGY_CONSUMPTION]: `Znamionowy pobór energii`,
    [k.REJECT_CHANGES]: `Reject changes`,
    [k.REMIND_PASSWORD]: `Remind password`,
    [k.RETURN_TO_THE_LOGIN_PANEL]: `return to the login panel`,
    [k.QUANTITY]: `Ilość`,
    [k.ROMANIAN]: `Romanian`,
    [k.SALE]: `Sale`,
    [k.SAVEANDCLOSE]: `Save and close`,
    [k.SAVECHANGES]: `Save changes`,
    [k.SEARCHFORPRODUCTS]: `Search for products`,
    [k.SEETHEPRODUCT]: `See the product`,
    [k.SETTINGS]: `Settings`,
    [k.SHOWCROSS_SECTION]: `Show cross-section`,
    [k.SHOWLAYERS]: `Show layers`,
    [k.SLOVAK]: `Slovak`,
    [k.SMALLQUANTITYINSTOCK]: `Small quantity in stock`,
    [k.SOMETHING_WENT_WRONG]: `Something went wrong:`,
    [k.SPAIN]: `Spain`,
    [k.RELOADAPPLICATION]: `Reload application`,
    [k.SWEDISH]: `Swedish`,
    [k.THERMOSTAT]: `Thermostat`,
    [k.THERMOSTATFUNCTIONS]: `Thermostat functions`,
    [k.THERMOSTATS]: `Thermostats`,
    [k.TOGETHER]: `Together`,
    [k.TOGETHERTOPAY]: `Together to pay`,
    [k.USABLEAREA]: `Powierzchnia użytkowa (m2)`,
    [k.ZEROENERGY]: `A++ Zero-energy`,
    [k.FLOOR]: `Floor`,
    [k.CEILING]: `Ceiling`,
    [k.WALL]: `Wall`,
    [k.WAREHOUSE]: `Warehouse`,
    [k.WHOLESALER]: `Hurtownia`,
    [k.WT2021]: `WT2021`,
    [k.ADDROOM]: `Add room`,
    [k.CHANGE]: `Change`,
    [k.YES]: `Yes`,
    [k.GOBACKTOCREATOR]: `Back to creator`,
    [k.GOBACKTOCART]: `Back to cart`,
    [k.SQUAREM]: `m²`,
    [k.PIECE]: `pcs`,
    [k.RUNNINGM]: `rm`,
    [k.COLOR]: `Color`,
    [k.UNDO]: `Undo`,
    [k.REDO]: `Redo`,
    [k.ZOOMIN]: `Zoom in`,
    [k.ZOOMOUT]: `Zoom out`,
    [k.MEASURE]: `Measure`,
    [k.CLOSEPOLYGON]: `Auto-close figure`,
    [k.CONTACTORREQUIRED]: `contactor required`,
    [k.WRONGLOGINORPASSWORD]: "Wrong login or password.",
    [k.WIDTH_IN_CM]: "Width in cm",
    [k.WIDTH]: "Width",
    [k.MISSINGUSER]: "User not found.",
    [k.PASSWORD]: "Password",
    [k.PASSWORDREQUIRED]: "Password is required.",
    [k.CONFIRMPASSWORDMUSTBESAME]: "Password confirmation musty be the same.",
    [k.PASSWORDSHOULDBEMIN8CHARLONG]: "Password mus be at least 8 character long.",
    [k.PASSWORDPATTERNQUOTE]: "Password must contain: 1 capital letter, 1 digit, 1 small letter and 1 special character (@, #, $, &).",
    [k.PASSWORD_HAS_BEEN_RESET]: "The password has been set",
    [k.CALIBRATION]: "Calibration",
    [k.READY]: "Ready",
    [k.EDIT]: "Edit",
    [k.START]: "Start",
    [k.UNFOLDAUTOMATICALLY]: "Unfold automatically",
    [k.CUTTINGPOINT]: "Cutting point",
    [k.TYPE]: "Type",
    [k.FURNITURE]: "Furniture",
    [k.BATH_SHOWERTRAY]: "Bath/Shower try",
    [k.SINK]: "Sink/Wall mounted toilet",
    [k.KITCHEN_FURNITURE]: "Kitchen furniture",
    [k.FIREPLACE]: "Fireplace",
    [k.HOUSEHOLD_APPLIANCES]: "Household appliances",
    [k.YOUR_ADDRESSES]: "Your addresses",
    [k.YOUR_NET_PRICE]: "Your net price",
    [k.YOUR_GROSS_PRICE]: "Your gross price",
    [k.OPTIMAL]: "Optimal",
    [k.VERTICALY]: "Vertical",
    [k.HORIZONTALY]: "Horizontal",
    [k.ALLWIDTHS]: "All possible widths",
    [k.DRAWOFFAREA]: "Do you want to add elements of permanent buildings?",
    [k.INNER]: "Inner",
    [k.OUTER]: "Outer",
    [k.OTHER]: "Other",
    [k.BATHROOM]: "Bathroom",
    [k.BASEMENT]: "Basement",
    [k.GROUND_FLOOR]: "Ground floor",
    [k.FIRST_FLOOR]: "Floor",
    [k.ATTIC]: "Attic",
    [k.STOREY]: "Storey",
    [k.LEVEL]: "Level",
    [k.ROOM]: "Room",
    [k.ADDFILE]: "Add file",
    [k.DRAWPLAN]: "Draw plan",
    [k.LIVINGROOM]: "LIVINGROOM",
    [k.KITCHEN]: "KITCHEN",
    [k.VESTIBULE]: "VESTIBULE",
    [k.BEDROOM]: "BEDROOM",
    [k.DININGROOM]: "DININGROOM",
    [k.WARDROBE]: "WARDROBE",
    [k.TOILET]: "TOILET",
    [k.LAUNDRY]: "LAUNDRY",
    [k.HALL]: "HALL",
    [k.CABINET]: "CABINET",
    [k.ANTEROOM]: "ANTEROOM",
    [k.CORRIDOR]: "CORRIDOR",
    [k.PANTRY]: "PANTRY",
    [k.LIVING_DINING_ROOM]: "LIVING_DINING_ROOM",
    [k.LIVINGROOM_WITH_KITCHENETTE]: "LIVINGROOM_WITH_KITCHENETTE",
    [k.GARAGE]: "GARAGE",
    [k.TECHNICALROOM]: "TECHNICALROOM",
    [k.UTILITYROOM]: "UTILITYROOM",
    [k.RECREATIONROOM]: "RECREATIONROOM",
    [k.ENTRESOL]: "ENTRESOL",
    [k.SAUNA]: "SAUNA",
    [k.BOILERROOM]: "BOILERROOM",
    [k.CUBBY]: "CUBBY",
    [k.STAIRS]: "STAIRS",
    [k.FIREPLACE_ROOM]: "FIREPLACE_ROOM",
    [k.GO_BACK_EDIT]: "Go back to edit",
    [k.GO_FURTHER]: "Go further",
    [k.DRAW_NEXT]: "Draw next",
    [k.ENTER_THE_EMAIL_PROVIDED_WHEN_CREATING_THE_ACCOUNT]: "Enter the email provided when creating the account.",
    [k.START_DESIGNING]: "Start designing",
    [k.IN_THE_WIZARD_HEAT_DECOR]: "in the wizard Heat Decor",
    [k.YOU_CAN_CREATE_AN_INFRARED_HEATING_PROJECT_IN_JUST_FEW_STEPS]: "You can create an infrared heating project in just a few simple steps.",
    [k.ENTER_THE_EMAIL_PROVIDED_WHEN_CREATING_THE_ACCOUNT]: "Enter the email provided when creating the account.",
    [k.WHAT_WILL_YOU_GAIN_BY_WORKING]: "What will you gain by working",
    [k.WITH_THE_HEAT_DECOR_CREATOR]: "with the Heat Decor creator?",
    [k.PRECISE_DESIGNS_OF_THE_HEATING_SYSTEM_IN_THE]: "• Precise designs of the heating system in the",
    [k.HEAT_DECOR_STANDARD_READY_FOR_USE_BY]: "Heat Decor standard, ready for use by",
    [k.THE_INSTALLATION_TEAM]: "the installation team",
    [k.EASIER_AND_MORE_COMPLETE_PREPARATION_OF_AN_OFFER_FOR]: "• Easier and more complete preparation of an offer for",
    [k.YOUR_CLIENT]: "your client",
    [k.FACILITATING_THE_PROCESS_OF_ORDERING_PRODUCTS]: "• Facilitating the process of ordering products",
    [k.HEAT_DECOR]: "Heat Decor",
    [k.WE_ARE_CHANGING_THE_FUTURE_FOR]: "We are changing the future for ",
    [k.GREEN]: "green",
    [k.WATCH_HEAT_DECOR]: "Watch Heat Decor",
    [k.READ_AND_SUBSCRIBE]: "read and subscribe",
    [k.BLOG]: "blog",
    [k.PRIMARY_USER]: "Primary User:",
    [k.HAS_ACCESS_TO_ALL_WIZARDS_FUNCTIONALITIES]: "• Has access to all wizard's functionalities.",
    [k.DEFINING_THEROLE_IN_THE_COMPANYS_STRUCTURE_WILL_FACILITATE_FURTHER_COOPERATION_WITH_HEAT_DECOR]:
        "• Określenie roli w strukturze firmy usprawni dalszą współpracę z Heat Decor.",
    [k.SPECIFYING_THE_TYPE_OF_COMPANY_WILL_ALLOW_YOU_TO_ADJUST_THE_WIZARD_TO_YOUR_NEEDS]: "• Specifying the type of company will allow you to adjust the wizard to your needs.",
    [k.AFTER_REGISTRATION_HEAT_DECOR_WILL_CONTACT_THE_INDICATED_MAIN_USER]: "• After registration, Heat Decor will contact the indicated main user.",
    [k.ADDITIONAL_USERS]: "Additional Users:",
    [k.THEY_CAN_USE_THE_WIZARD_TO_DEVELOP_DESIGNS_AND_QUOTES_FOR_THEIR_CLIENTS]: "• They can use the wizard to develop designs and quotes for their clients.",
    [k.THEY_HAVE_LIMITED_ACCOUNT_EDITING_CAPABILITIES]: "• They have limited account editing capabilities.",
    [k.IN_CASE_OF_REPEATED_DIFFICULTIES_WITH_USING_THE_WIZARD_CONTACT_THE_ADMINISTRATOR_OF_THE_HEAT_DECOR_WIZARD]:
        "In case of repeated difficulties with using the wizard, please contact the administrator of the Heat Decor wizard:",
    [k.EMAIL]: "Email",
    [k.TELEPHONE]: "Telephone:",
    [k.PRIVACY_POLICY]: "Privacy policy",
    [k.WEBSITE_REGULATIONS]: "Website regulations",
    [k.THANK_YOU_FOR_YOUR_REGISTRATION]: "Thank you for your registration",
    [k.LOGGING_IN_WILL_BE_POSSIBLE_AFTER_SUCCESSFULLY]: "Logging in will be possible after successfully",
    [k.SETTING_THE_PASSWORD_AND_VERIFYING_THE_ACCOUNT_BY_THE]: "setting the password and verifying the account by the",
    [k.ADMINISTRATOR]: "Administrator.",
    [k.YOU_WILL_RECEIVE_A_LINK_TO_SET_A_NEW_PASSWORD]: "You will receive a link to set a new password.",
    [k.EMAIL_WAS_SENT_FOLLOW_THE_INSTRUCTIONS]: "Email was sent. Follow the instructions",
    [k.IN_THE_EMAIL]: " in the email.",
    [k.CHECK_EMAIL]: "Check email",
    [k.TO_COMPLETE_THE_REGISTRATION_PROCESS_CHECK_THE_MESSAGE_SENT_AUTOMATICALLY_TO_THE_EMAIL_ADDRESS_PROVIDED_AND_CLICK_THE_LINK_TO_CONFIRM_THE_REGISTRATION]:
        "To complete the registration process, check the message sent automatically to the e-mail address provided and click the link to confirm the registration.",
    [k.REGISTRATION_ERROR]: "Registration error",
    [k.AN_ERROR_OCCURRED_WHILE_CREATING_A_NEW_ACCOUNT]: "An error occurred while creating a new account.",
    [k.TRY_AGAIN_IF_THE_ERROR_REPEATS_TRY_TO_REGISTER_TO_A_DIFFERENT_EMAIL_ADDRESS_OR_CONTACT_US]:
        "Try again. If the error repeats, try to register to a different email address or contact us.",
    [k.ENTER_THE_ACCOUNT_PRIMARY_USER_INFORMATION]: "Enter the account primary user information.",
    [k.REGISTRATION]: "Registration",
    [k.ADDITIONAL_USERS_OPTIONAL]: "Additional users (optional)",
    [k.INVESTMENT]: "Investment:",
    [k.INVESTORS_NAME]: "Investor's name:",
    [k.ADDED_BY]: "Added by:",
    [k.NOTES]: "Notes",
    [k.STREET]: "Street",
    [k.CITY]: "City",
    [k.ZIP_CODE]: "ZIP code",
    [k.NET_PRICE_FOR_THE_CLIENT]: "Net price for the client:",
    [k.NET_PROFIT]: "Net profit:",
    [k.DOWNLOAD_MULTIPLE_PDF_FILES]: "Download multiple PDF files",
    [k.DELETE_PROJECTS]: "Delete projects",
    [k.ADD_TO_CART]: "Add to cart",
    [k.REMOVE_OFFERS]: "Remove offers",
    [k.GENEROWANIE_OFERTY]: "Generating an offer",
    [k.RESET_PASS]: "Reset password",
    [k.VALUE_OF_THE_OFFER]: "Value of the offer",
    [k.YOUR_COST]: "Twój koszts",
    [k.YOUR_PRICE]: "Twoja cena",
    [k.THE_INVESTOR_PAYS]: "The investor pays",
    [k.YOU_EARN]: "You earn",
    [k.GO_ON]: "Go on",
    [k.ENTER_NEW_PASSWORD]: "Enter a new password",
    [k.NEW_PASSWORD]: "New password",
    [k.PRICE]: "Cena",
    [k.PRICE_FOR_THE_CLIENT]: "Price for the client:",
    [k.A_PRICE_FOR_YOU]: "A price for you:",
    [k.INCORRECT_LOGIN_OR_PASSWORD]: "Incorrect login or password.",
    [k.OFFER]: `Offer`,
    [k.BY]: `by`,
    [k.LIST_OF_ORDERS]: "List of orders",
    [k.THANK_YOU_FOR_YOUR_ORDER]: "Thank you for your order",
    [k.GO_TO_ORDERS]: "Go to orders",
    [k.SMS_CODE]: "SMS code",
    [k.INCORRECT_AUTHORIZATION_CODE]: "Incorrect authorization code",
    [k.ENTER_AUTHORIZATION_CODE]: "Enter the authorization code",
    [k.CONFIRMED]: "Confirmed",
    [k.RESEND_CODE]: "Resend code",
    [k.REENTER_THE_6_DIGIT_CODE]: "Re-enter the 6 digit SMS code #",
    [k.TO_VERIFY_YOUR_ACCOUNT]: "to verify your account",
    [k.ENTER_6_DIGIT_SMS_CODE_TO_VERIFY_YOU_ACCOUNT]: "Enter a 6 digit SMS code to verify your account",
    [k.NR]: "#",
    [k.GO_TO_PAYMENT]: "Go to payment",
    [k.ORDERED_PRODUCTS]: "Ordered products",
    [k.NOTES_TO_THE_ORDER]: "Notes to the order",
    [k.NO_PRODUCTS]: "No products",
    [k.THERE_ARE_NO_PRODUCTS_IN_YOUR_CART]: "There are no products in your cart",
    [k.NO_OFFERS]: "No offers",
    [k.YOUR_CART]: "Your cart",
    [k.YOUR_ORDER_WITH_DELIVERY_TO_THE_CUSTOMER]: "Your order with delivery to the customer",
    [k.YOUR_ORDER_WITH_DELIVERY_TO_YOUR_WEREHOUSE]: "Your order with delivery to your warehouse",
    [k.PAY_FOR_YOUR_ORDER]: "Pay for your order",
    [k.PHONE]: "Phone",
    [k.SHIPPING_TO]: "Shipping to",
    [k.SAVE]: "Save",
    [k.EMAIL_IS_REQUIRED]: "Email is required",
    [k.CODE_AND_CITY_ARE_REQUIRED]: "Code and city are required",
    [k.POSTAL_CODE_AND_CITY]: "Postal code and city",
    [k.STREET_AND_BUILDING_NUMBER_ARE_REQUIRED]: "Street and building number are required",
    [k.STREET_AND_NUMBER]: "Street and number",
    [k.NAME_AND_SURNAME]: "Name and surname",
    [k.DISCOUNT]: "Discount",
    [k.SUM]: "Sum",
    [k.YOU_WANT_TO_GIVE_A_DISCOUNT_ON_ALL_MATERIAL]: "You want to give a discount on all material",
    [k.ALL_MATERIAL_DISCOUNT]: "ALL MATERIAL DISCOUNT",
    [k.SORRY_NOTHING_HERE]: "Sorry... nothing here.",
    [k.GO_HOME]: "Go home",
    [k.I_UNDERSTAND_DO_NOT_SHOW_ON_THE_NEXT_LOGIN]: "I understand, do not show on the next login",
    [k.DISTRIBUTOR]: "Distributor",
    [k.GENERATE_REPORT]: "Generate report",
    [k.SKETCH]: "Sketch",
    [k.GENERATE_AN_OFFER]: "Generate offer",
    [k.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_SELECTED_PROJECT]: "Are you sure you want to delete the selected project?",
    [k.FIND_A_PROJECT]: "Find a project",
    [k.THERE_IS_NO_SUCH_PROJECT]: "There is no such project",
    [k.YOU_DONT_HAVE_ANY_PROJECT_YET]: "You don't have any projects yet",
    [k.YOU_MUST_HAVE_AN_INVESTOR_AND_AN_INVESTMENT_TO_CREATE_A_PROJECT_FOR_IT]: "You must have an investor and an investment to create a project for it",
    [k.DO_YOU_WANT_TO_CREATE_AN_INVESTOR]: "Do you want to create an investor",
    [k.GETTING_STARTED_WITH_ADDING_AN_INVESTOR]: "Getting started with adding an investor",
    [k.ADD_AN_INVESTOR]: "Add investor",
    [k.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN]: "Something went wrong, please try again",
    [k.SELECT_THE_TYPE_OF_ROOM]: "Select the type of the room",
    [k.GO_BACK_TO_EDITING]: "Go back to editing",
    [k.TITLE]: "Title",
    [k.GROUP]: "Group",
    [k.GENERAL]: "General",
    [k.HEATING_FOIL]: "Heating foil",
    [k.ELECTRIC_HEATERS]: "Eletric heaters",
    [k.HEATING_MAT]: "Heating mat",
    [k.IMAGE]: "Image",
    [k.DRAG_AND_DROP_THE_IMAGE_OR_CLICK_AND_SELECT]: "Drag and drop the image or click and select",
    [k.PREVIEW]: "Preview",
    [k.TEXT_EDITOR]: "Text editor",
    [k.SAVE_CHANGES]: "Save changes",
    [k.EXIT]: "Exit",
    [k.ADD_A_NEW_COMPANY]: "Add a new company",
    [k.GO_BACK_TO_THE_LIST]: "Go back to the list",
    [k.FIRST_NAME]: "First name",
    [k.LAST_NAME]: "Last name",
    [k.USER]: "User",
    [k.IS_REQUIRED]: "is required",
    [k.FIRST_NAME_IS_REQUIRED]: "First name is required",
    [k.LAST_NAME_IS_REQUIRED]: "Last name is required",
    [k.PHONE_IS_REQUIRED]: "Phone is required",
    [k.EMAIL_IS_INVALID]: "Email is invalid",
    [k.ADD_USER]: "Add a user",
    [k.REGISTER]: "Register",
    [k.COUNTRY]: "Country",
    [k.PHONE_NUMBER]: "Phone number",
    [k.PREFIX_IS_REQUIRED]: "Prefix is required",
    [k.NEXT_STEP]: "Next step",
    [k.TAX_ID_IS_REQUIRED]: "Tax ID is required",
    [k.COUNTRY_IS_REQUIRED]: "Country is required",
    [k.TAX_ID]: "Tax ID",
    [k.TAX_ID_IS_INCORRECT]: "Tax ID is incorrect",
    [k.ZIP_CODE_IS_REQUIRED]: "Zip code is required",
    [k.CITY_IS_REQUIRED]: "City is required",
    [k.HOUSE_NUMBER_IS_REQUIRED]: "House number is required",
    [k.NUMBER]: "Number",
    [k.DOCUMENT_NUMBER]: "Document number",
    [k.DOWNLOAD]: "Download",
    [k.WAS_THIS_ARTICLE_HELPFUL]: "Was this article helpful",
    [k.MIN_READING]: "min reading",
    [k.SEE_MORE]: "SEE MORE",
    [k.ADDRESS]: "Address",
    [k.DEFAULT_ADDRESS]: "Default address",
    [k.NAME]: "Name",
    [k.NAME_OF_THE_INVESTMENT]: "Name of the investment",
    [k.SEARCH_CRITERIA]: "Search criteria",
    [k.WHO_ADDED]: "Who added",
    [k.IS_IT_A_COMPANY]: "Is it a company",
    [k.BUSINESS]: "Business",
    [k.NATURAL_PERSON]: "Natural person",
    [k.YOU_DONT_HAVE_ANY_INVESTOR_YET]: "You don't have any investor yet",
    [k.DO_YOU_WANT_TO_ADD_IT_NOW]: "Do you want to add it now",
    [k.THERE_IS_NO_SUCH_INVESTOR]: "There is no such investor",
    [k.YOU_DONT_HAVE_ANY_INVESTMENTS_YET]: "You don't have any investments yet",
    [k.ADD_YOUR_FIRST_INVESTMENT]: "Add your first investment",
    [k.THERE_IS_NO_SUCH_INVESTMENT]: "There is no such investment",
    [k.ADD_AN_INVESTMENT]: "Add an investment",
    [k.INVESTOR]: "Investor",
    [k.CONTACT_PERSON]: "Contact person",
    [k.STREET_IS_REQUIRED]: "Street is required",
    [k.NO_IS_REQUIRED]: "NO is required",
    [k.CODE_IS_REQUIRED]: "Code is required",
    [k.THE_ENERGY_CLASS_IS_REQUIRED]: "The energy Class is required",
    [k.CUSTOM_ENERGY_CLASS_IS_REQUIRED]: "Custom energy class. is required",
    [k.GENERATE_OFFERS]: "Generate offers",
    [k.THE_OFFER_IS_ALREADY_IN_THE_CART_ARE_YOU_SURE_YOU_WANT_TO_REMOVE_IT]: "The offer is already in the basket, are you sure you want to remove it?",
    [k.COMPANY_NAME_IS_REQUIRED]: "Company name is required",
    [k.THE_ADDRESS_IS_REQUIRED]: "The address is required",
    [k.THE_ZIP_CODE_IS_INCORRECT]: "The zip code is incorrect",
    [k.THE_CITY_IS_NOT_CORRECT]: "The city is not correct",
    [k.THE_HOUSE_NUMBER_IS_INCORRECT]: "The house number is incorrect",
    [k.COMPANY_NAME]: "Company name",
    [k.PREFIX_AND_PHONE_NUMBER_ARE_REQUIRED]: "Prefix and phone number are required",
    [k.POSTAL_CODE_AND_CITY_ARE_REQUIRED]: "Postal code and city are required",
    [k.STREET_AND_HOUSE_NUMBER_ARE_REQUIRED]: "Street and house number are required",
    [k.FULL_NAME_IS_REQUIRED]: "Full name is required",
    [k.REQUIRED_FIELD]: "Required field",
    [k.REQUIRED]: "Required",
    [k.ADD_A_PROJECT]: "Add a project",
    [k.PROJECT_NAME_IS_REQUIRED]: "Project name is required",
    [k.THE_BUILDING_NUMBER_IS_REQUIRED]: "The building number is required",
    [k.No]: "No",
    [k.ADDITIONAL_CONTACT_PERSON]: "Additional contact person",
    [k.CLASS_ENERGY_IS_REQUIRED]: "Class en. is required",
    [k.ENERGY_CLASS]: "Energy class",
    [k.THE_STREET_IS_NOT_CORRECT]: "The street is not correct",
    [k.HOUSE_NUMBER]: "House number",
    [k.TYPE_OF_INVESTMENT]: "Type of investment",
    [k.CODE]: "Code",
    [k.THE_INVESTMENT_HAS_NOT_BEEN_ADDED_TRY_AGAIN]: "The investment has not been added. try again",
    [k.COMPANY_TYPE]: "Company type",
    [k.PHONE_NUMBER_TO_VERIFY_THE_ACCOUNT_WITH_AN_SMS_CODE]: "Phone number to verify the account with an SMS code",
    [k.GRAPHICS]: "Graphics",
    [k.GO_BACK_TO_LOGIN]: "Go back to login",
    [k.ADD_A_NEW_INVESTMENT]: "Add a new investment",
    [k.GO_BACK_TO_THE_LIST_OF_INVESTMENTS]: "Go back to the list of investments",
    [k.ADD_A_NEW_INVESTOR]: "Add a new investor",
    [k.COMPLETE_THE_INVESTOR_PROFILE]: "Complete the investor's profile",
    [k.GO_BACK_TO_INVESTORS]: "Go back to investors",
    [k.PROJECT_NAME]: "Project name",
    [k.WHAT_DO_YOU_WANT_TO_DESIGN]: "What do you want to design",
    [k.UPLOAD_THE_FILE_WITH_THE_VIEW]: "Upload the file with the view",
    [k.ADD_FILE]: "ADD FILE",
    [k.DISCARD_CHANGES]: "Discard changes",
    [k.DRAW_ANY_ROOM_PLAN]: "Draw any room plan",
    [k.GENERATE_AS_IMAGE]: "Generate as image",
    [k.DRAW_PLAN]: "DRAW PLAN",
    [k.CREATE_A_PROJECT]: "Create a project",
    [k.CREATE_A_THROW_IN_THE_WIZARD]: "Create a throw in the wizard",
    [k.COME_BACK]: "Come back",
    [k.HEATING_MAT_UNDER_THE_TILES]: "Heating mat under the tiles",
    [k.HEATING_FOIL_UNDER_THE_SPOUT]: "Heating foil under the spout",
    [k.HEATING_FOIL_UNDER_THE_SPC_VINYL_PANELS]: "Heating foil under the SPC vinyl panels",
    [k.HEATING_FOIL_FOR_LAMINATED_PANELS]: "Heating foil for laminated panels",
    [k.SELECT_A_HEATING_SYSTEM]: "Select a heating system",
    [k.GIVE_THE_REAL_DIMENSION]: "GIVE THE REAL DIMENSION",
    [k.ENTER_THE_REAL_LENGTH]: "Enter the real length",
    [k.DESIGNATED_EPISODE]: "designated episode",
    [k.SEE_PREVIEW]: "See preview",
    [k.ADJUST_THE_VIEW_BY_TRIMMING_AND_ROTATING_IT]: "Adjust the view by trimming and rotating it",
    [k.THERE_ARE_NO_PRODUCTS_FOR_THE_GIVEN_CRITERIA]: "There are no products for the given criteria",
    [k.CHOOSE_A_MODEL]: "CHOOSE MODEL",
    [k.PLN_GROSS]: "PLN gross",
    [k.FOR_CALIBRATION_MARK_THE_LONGEST_SIDE_OF_THE_ROOM]: "For calibration, mark the longest side of the room",
    [k.STEP1_DRAW_THE_OUTLINE_OF_THE_ROOM]: "Step 1: Draw the outline of the room",
    [k.SELECT_THE_TYPE_OF_THE_SELECTED_FIXED_BUILDING_ELEMENT]: "Select the type of the selected fixed building element",
    [k.STEP2_APPLY_THE_SURFACES_EXCLUDED_FOR_PERMANENT_CONSTRUCTION]: "Step 2: Apply the surfaces excluded for permanent construction",
    [k.ADD_A_LAYER_OF_VAPOR_BARRIER_FOIL]: "Add a layer of vapor barrier foil",
    [k.PLACE_THE_THERMOREGULATOR]: "Place the thermoregulator",
    [k.SELECT_THE_ENERGY_CLASS_OF_THE_BUILDING]: "Select the energy class of the building",
    [k.THE_PROJECT_HAS_UNSAVED_CHANGES_ARE_YOU_SURE_YOU_WANT_TO_EXIT_THE_WIZARD]: "The project has unsaved changes. Are you sure you want to exit the wizard?",
    [k.DO_YOU_WANT_TO_SAVE_THE_CHANGES]: "Do you want to save the changes?",
    [k.CONFIRM_NEW_PASSWORD]: "Confirm new password",
    [k.SET_A_PASSWORD]: "Set a password",
    [k.THE_PHONE_IS_NOT_VALID]: "The phone is not valid",
    [k.MANAGE_ADDRESSES]: "Manage addresses",
    [k.PLEASE_SELECT_A_SHIPPING_ADDRESS]: "Please select a shipping address",
    [k.ADD_A_COMPANY]: "Add a company",
    [k.SEARCH_FOR_A_COMPANY]: "Search for a company",
    [k.MESSAGE]: "Message",
    [k.NO_EMAIL_ADDRESS]: "No email address",
    [k.EDIT_CUSTOMER_DETAILS]: "Edit customer details",
    [k.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_COMPANY]: "Are you sure you want to delete this company?",
    [k.RULER_CALIBRATION]: "Ruler calibration",
    [k.CALIBRATE_TOOL_TO_TEACH_A_DISTANCE_CALCULATION_PROGRAM_FOR_YOUR_THROW]: "Calibrate Tool to teach a distance calculation program for your throw",
    [k.SEE_AN_EXAMPLE]: "See an example",
    [k.CALCULATE_YOUR_HEATING_COSTS]: "Calculate your heating costs",
    [k.THE_AREA_OF_THE_ROOM]: "The area of ​​the room",
    [k.PERMANENT_BUILDINGS]: "Permanent buildings",
    [k.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_ROOM]: "Are you sure you want to delete the room?",
    [k.THERMOREGULATORS]: "Thermoregulators",
    [k.FLOOR_SENSOR]: "Floor sensor",
    [k.INDOOR_AIR_SENSOR]: "Indoor air sensor",
    [k.OUTSIDE_AIR_SENSOR]: "Outside air sensor",
    [k.OPTIONAL]: "Optional",
    [k.PROGRAMMING]: "Programming",
    [k.LEVEL_OF_SECURITY]: "Level of security",
    [k.CONTROL_PANEL]: "Control panel",
    [k.TOUCH]: "Touch",
    [k.KEYBOARD]: "Keyboard",
    [k.KNOB]: "Knob",
    [k.FIND_OUT_MORE]: "Find out more",
    [k.CHOOSE]: "Choose",
    [k.ARE_YOU_SURE_YOU_WANT_TO_REMOVE_THIS_PRODUCT_FROM_YOUR_CART]: "Are you sure you want to remove this product from your cart?",
    [k.FILTER]: "FILTER",
    [k.CUTTING]: "Cutting",
    [k.POWER]: "Power",
    [k.MAX_TEMP]: "Max temp.",
    [k.AREA]: "Area",
    [k.HEATING_MAT_POWER]: "Heating mat power",
    [k.HEIGHT]: "Height",
    [k.THICKNESS]: "Thickness",
    [k.PRODUCT_PARAMETERS]: "PRODUCT PARAMETERS",
    [k.LENGTH]: "Length",
    [k.HEATING_POWER]: "Heating power",
    [k.FOIL_SURFACE]: "Foil surface",
    [k.MAX_HEATING_TEMPERATURE]: "Max heating temperature",
    [k.THE_WIDTH_OF_THE_FILM]: "The width of the film",
    [k.POSSIBILITY_TO_CUT_THE_FILM_CO]: "Possibility to cut the film co",
    [k.MAT_AREA]: "Mat area",
    [k.THERMOSTAT_MODEL]: "Thermostat model",
    [k.RESISTANCE]: "Resistance",
    [k.FOIL_MODEL]: "Foil model",
    [k.BRAND]: "Brand",
    [k.WEIGHT]: "Weight",
    [k.FOIL_GUARANTEE]: "Foil guarantee",
    [k.CONNECTION_KITS_PCS]: "Connection sets [pcs]",
    [k.WIRES]: "Wires",
    [k.TAPE_PCS]: "Tape [pcs]",
    [k.THERMOSTAT_WARRANTY]: "Thermostat warranty",
    [k.CONNECTOR]: "Connector",
    [k.MAT_GUARANTEE]: "Mat guarantee",
    [k.PRO_TAPE_PCS]: "PRO tape [pcs.]",
    [k.PRODUCT_DESCRIPTION]: "Product description",
    [k.RETURN_TO_THE_KNOWLEDGE_BASE]: "Return to the knowledge base",
    [k.SIMILAR_ARTICLES]: "Similar articles",
    [k.NUMBER_OF_INVESTMENTS]: "Number of Investments",
    [k.VIEW_PROFILE]: "View profile",
    [k.LATEST_INVESTMENTS]: "Latest investments",
    [k.THERE_ARE_NO_PRODUCTS_IN_THE_PROJECT]: "There are no products in the project",
    [k.BILL_OF_MATERIALS_FOR_PROJECTS]: "Bill of materials for projects",
    [k.YOUR_CALCULATION_WITHOUT_ASSEMBLY]: "YOUR CALCULATION WITHOUT ASSEMBLY",
    [k.YOU_WANT_TO_ADD_A_MONTAGE]: "You want to add a montage",
    [k.NUMBER_OF_KILOMETERS]: "Number of kilometers",
    [k.PRICE_FOR_ASSEMBLY]: "Price for assembly",
    [k.YOUR_CALCULATION_WITH_ASSEMBLY]: "YOUR CALCULATION WITH ASSEMBLY",
    [k.CHECK_WHAT_RATE_YOU_ARE_AT]: "Check what rate you are at",
    [k.SHOW_HEATING_COSTS]: "Show heating costs",
    [k.USABLE_AREA]: "Usable area",
    [k.SELECTED_ENERGY_CLASS]: "Selected energy class",
    [k.THE_PRICE_OF_ELECTRICITY]: "The price of electricity",
    [k.HEATING_PRICE_IN_KWH_YEAR]: "Heating price in kWh / year",
    [k.SAVE_AND_GENERATE_AN_OFFER]: "Save and generate an offer",
    [k.NUMBER_OF_PROJECTS]: "Number of projects",
    [k.GO_TO_INVESTMENT]: "Go to investment",
    [k.LATEST_PROJECTS]: "Latest projects",
    [k.ASSEMBLY]: "Assembly",
    [k.START_TYPING]: "Start typing",
    [k.ORDERS_PLACED]: "Orders placed",
    [k.NO_ORDERS_PLACED]: "No orders placed",
    [k.SUBMIT]: "Submit",
    [k.SAYS]: "says",
    [k.ROTATE]: "Rotate",
    [k.SCALE]: "Scale",
    [k.ADD_A_NEW_PROJECT]: "Add a new project",
    [k.GO_BACK_TO_PROJECTS]: "Go back to projects",
    [k.SELECT_THE_SIDE_ON_WHICH_THE_VIEW_IS_LOCATED]: "Select the side on which the view is located",
    [k.STEP]: "Step",
    [k.CREATED_BY]: "created by",
    [k.INVESTOR_NAME]: "Investor name",
    [k.YOU_DONT_HAVE_ANY_OFFER_YET]: "You don't have any offer yet",
    [k.CURRENT_VIEW_CREATOR_SAVED]: `Current view from creator will be saved to the PDF offer.`,
    [k.ENTER_DWG_FILE_SIZE]: `Enter DWG file size.`,
    [k.CREATED_BY2]: `Utworzono przez`,
    [k.DO_YOU_WANT_TO_SAVE_CHANGES]: `Do you want to save changes?`,
    [k.NUMBER_OF_ROOMS]: `Ilość pomieszczeń`,
    [k.FOIL]: "Foil",
    [k.MAT]: "Mat",
    [k.NETTO_PRICE]: "Net price",
    [k.QUANTITY_PCS_M]: "Quantity pcs/m",
    [k.NET_VALUE]: "Wartość netto",
    [k.MATERIAL_COST]: "Material cost",
    [k.INVESTOR_COST]: "Investor's cost",
    [k.ADD_NOTE]: "Add note",
    [k.REMOVE_PROJECT_FROM_OFFER]: "Remove project from offer",
    [k.COVERING_AREA_OF_ROOM]: "Covering the area of the room",
    [k.SEE_ASSORTMENT]: "See assortment",
    [k.LP]: "No",

    [k.SUMMARY]: "Summary",
    [k.THE_ORDER_HAS_BEEN_FORWARDED_FOR_EXECUTION]: "The order has been forwarded for execution",
    [k.THE_STATUS_OF_THE_ORDER_CAN_BE_TRACKED_IN_THE_ORDERS_TAB]: "The status of the order can be tracked in the Orders tab",
    [k.THANK_YOU_FOR_YOUR_TRUST]: "Thank you for your trust",

    [k.TO_PAY]: "To pay",
    [k.SHIPPING_TO_YOUR_OWN_WAREHOUSE]: "Shipping to your own warehouse",
    [k.SHIPPING_TO_THE_CUSTOMER]: "Shipping to the customer",
    [k.YOU_HAVE_RECEIVED_AN_ADDITIONAL_DISCOUNT_RESULTING_FROM_COMBINING_TWO_INVESTMENTS_INTO_ONE_ORDER]:
        "You have received an additional discount resulting from combining two investments into one order",
    [k.SHIPPING_ADDRESS]: "Shipping address",
    [k.ADDRESSEE]: "Addressee",
    [k.WAREHOUSE_ADDRESS]: "Warehouse address",
    [k.CONTACT]: "Contact",
    [k.CHOOSE_PAYMENT_METHOD]: "Choose payment method",
    [k.TRADITIONAL_BANK_TRANSFER]: "Traditional bank transfer",
    [k.PAYMENT_DEADLINE]: "Payment deadline",
    [k.CASH_ON_DELIVERY]: "Cash on delivery",
    [k.INVESTMENT_DATA]: "Investment data",
    [k.DELIVERY_ADDRESS]: "Delivery address",
    [k.LIST_OF_PRODUCTS]: "List of products",
    [k.PRODUCT_NAME]: "Product name",
    [k.AMOUNT_OF_PRODUCTS]: "Amount of products",
    [k.NET_PRICE]: "Net price",
    [k.GROSS_PRICE]: "Gross price",
    [k.INFO_IF_YOU_WANT_TO_ADD_RESERVE_MATERIAL_TO_AN_INVESTMENT_CLICK_EDIT_IN_THE_TOP_RIGHT_CORNER_OF_THE_PRODUCT_LIST]:
        "Info: If you want to add reserve material to an investment, click edit in the top right corner of the product list.",
    [k.INVESTMENT_ADDRESS]: "Investment address",
    [k.BANK_TRANSFER_DETAILS]: "Bank transfer details",
    [k.RECIPIENT]: "Recipient",
    [k.ACCOUNT_NUMBER]: "Account number",
    [k.DOWNLOAD_PDF]: "Download PDF",
    [k.GO_TO_ORDER]: "Go to order",
    [k.CHOOSE_A_BANK]: "Choose a bank",
    [k.THE_VALUE_OF_THE_MATERIALS]: "The value of the materials",
    [k.SHOPPING_CART_ITEMS]: "Shopping cart items",
    [k.INFO_TO_EDIT_THE_SHIPPING_ADDRESS_CLICK_ON_THE_PENCIL_IN_THE_UPPER_RIGHT_CORNER]: "Info: To edit the shipping address, click on the pencil in the upper right corner",
    [k.NO_ORDERS]: "No orders",
    [k.STATUS_OF_THE_ORDER]: "Status of the order",
    [k.DATE]: "Date",
    [k.SHIPPING_NO]: "Shipping No.",
    [k.DOWNLOAD_THE_INVOICE]: "Download the invoice",
    [k.MORE_INFORMATION]: "More information",
    [k.SHIPPING_INFORMATION]: "Shipping information",
    [k.ORDER_STATUS]: "Order status",
    [k.CONTACT_PERSON2]: "Contact person",
    [k.ORDER_NOTES]: "Order notes (optional)",
    [k.QUOTA]: "Sum",
    [k.GETTING_STARTED_ON_A_PROJECT_STARTS_WITH_ADDING_AN_INVESTMENT]: "Getting started on a project starts with adding an investment",
    [k.DUPLICATE]: "Duplicate",
    [k.ROOMS]: "Rooms",
    [k.COMPLETED_OFFERS]: "Completed offers",
    [k.NO_PROJECTS]: "No projects",
    [k.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_SELECTED_PROJECTS]: "Are you sure you want to delete the selected projects?",
    [k.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_SELECTED_OFFERS]: "Are you sure you want to delete the selected offers?",
    [k.YOUR_ADVANTAGE_IN_ASSEMBLY]: "Your advantage in assembly",
    [k.YOUR_BENEFIT_ON_THE_MATERIAL]: "Your benefit on the material",
    [k.ADDED_TO_CART]: "Added to cart",
    [k.CREATED]: "Created",
    [k.PENDING]: "Pending",
    [k.COMPLETE]: "Complete",
    [k.REJECTED]: "Rejected",
    [k.COMPANY]: "Company",

    [k.GO_TO_OFFERS]: "Go to offers",
    [k.DONE_WITH_INSTALLATION]: "Done with installation",
    [k.DONE_WITHOUT_INSTALLATION]: "Done without installation",
    [k.THE_NAME_OF_THE_INVESTMENT_IS_REQUIRED]: "Name of the investment is required",
    [k.INVESTOR_DATA]: "Investor data",
    [k.USERS]: "Users",
    [k.RULES]: "Rules",
    [k.CONTACT_DETAILS]: "Contact details",
    [k.THE_LOGO_WILL_BE_USED_ON_THE_OFFER_AND_INVOICES_FOR_THE_CUSTOMER]: "The logo will be used on the offer and invoices for the customer",
    [k.UPLOAD_THE_FILE]: "Upload the file",
    [k.UPLOAD_THE_LOGO_FILE]: "Upload the logo file",
    [k.MAX_SIZE]: "Max size",
    [k.MAX_DIMENSIONS]: "Max dimension",
    [k.CURRENT_FILE]: "Current file",
    [k.NO_PHOTO]: "No photo",
    [k.DELETE_FILE]: "Delete file",
    [k.WIZARD_LANGUAGE]: "Wizard language",
    [k.COMPANY_LOGO]: "Company logo",
    [k.MAIN_USER]: "Main user",
    [k.OTHER_USERS]: "Other Users",
    [k.ADD_NEW_PROFILE]: "add a new profile",
    [k.CHANGES_HAVE_BEEN_MADE_DO_YOU_WANT_TO_SAVE_THEM]: "Changes have been made, do you want to save them?",
    [k.DISCARD]: "Discard",
    [k.POSITION_OPTIONAL]: "Position (optional)",
    [k.LOCK_PROFILE]: "lock profile",
    [k.DELETE_PROFILE]: "delete profile",
    [k.DO_SAVE_CHANGES]: "Save changes?",
    [k.POSITION]: "Position",
    [k.THE_NAME_OF_THE_ENERGY_CLASS_IS_REQUIRED]: "Name of the energy class is required",
    [k.THE_NAME_OF_THE_ENERGY_CLASS]: "Name of the energy class",
    [k.EU_DEMAND]: "EU demand",
    [k.EU_REQUIREMENT_IS_REQUIRED]: "EU requirement is required",
    [k.HEATING_SYSTEM_UNDER_THE_SPOUT]: "Heating system under the spout",
    [k.HEATING_SYSTEM_UNDER_THE_SPOUT_IS_REQUIRED]: "Heating system under the spout is required",
    [k.HEATING_SYSTEM_UNDER_THE_SPOUT_FOR_ROOMS_BATHROOM_TOILET_LAUNDRY_ROOM_SAUNA]: "Heating system under the spout for rooms: bathroom, toilet, laundry room, sauna.",
    [k.HEATING_SYSTEM_UNDER_THE_SPOUT_FOR_ROOMS_BATHROOM_TOILET_LAUNDRY_SAUNA_IS_REQUIRED]:
        "Heating system under the spout for rooms: bathroom, toilet, laundry, sauna is required",
    [k.HEATING_SYSTEM_UNDER_THE_PANEL]: "Heating system under the panel",
    [k.HEATING_SYSTEM_UNDER_THE_PANEL_IS_REQUIRED]: "Heating system under the panel is required",
    [k.REMOVE_ENERGY_CLASS]: "remove energy class",
    [k.TABLE_OF_ENERGY_CLASSES]: "Table of energy classes",
    [k.EU_DEMAND_EVERY_KWH]: "EU demand every KWh / m2 * year",
    [k.SYSTEM_UNDER_THE_SPOUT]: "System under the spout",
    [k.SYSTEM_UNDER_THE_SPOUT_FOR_ROOMS_BATHROOM_TOILET_LAUNDRY_ROOM_SAUNA]: "System under the spout for rooms (bathroom, toilet, laundry room, sauna)",
    [k.SYSTEM_UNDER_THE_PANEL]: "System under the panel",
    [k.INFO_YOU_CAN_ADD_YOUR_OWN_ENERGY_CLASS_SETTINGS]: "Info: You can add your own energy class settings.",
    [k.ADD_YOUR_OWN_ENERGY_CLASS]: "Add your own energy class",
    [k.ASSEMBLY_RULE]: "Assembly rule",
    [k.ATTENTION_THE_NET_AMOUNT_IS_CALCULATED_FROM_THE_USABLE_AREA_OF_THE_ROOM]: "Attention! The net amount is calculated from the usable area of the room (outline).",
    [k.HEAT_DECOR_RECOMMENDATION]: "Heat Decor recommendation",
    [k.ATTENTION_THE_NET_AMOUNT_IS_CALCULATED_ON_THE_SURFACE_OF_THE_HEATING_MAT]: "Attention! The net amount is calculated on the surface of the heating mat.",
    [k.VAT_RULE]: "VAT rule",
    [k.ACTIVE_VAT_RULES]: "Active VAT rules",
    [k.ADD_A_NEW_USER]: "Add new user",
    [k.UNLOCK_PROFILE]: "unlock profile",
    [k.NET_PRICE_PER_M2]: "Net price per m2",
    [k.SYSTEM_FOR_TILES]: "System for tiles",
    [k.INSULATION_MAT]: "5 mm insulation mat",
    [k.XPS_EXTRUDED_POLYSTYRENE]: "6 mm XPS extruded polystyrene",
    [k.SYSTEM_FOR_PANELS_6_MM_XPS_UNDERLAY]: "System for panels (6 mm XPS underlay)",
    [k.SYSTEM_FOR_PANELS_5_MM_BASE]: "System for panels (5 mm base)",
    [k.THE_MINIMUM_VALUE_MUST_NOT_BE_GREATER_THAN_THE_MAXIMUM]: "The minimum value must not be greater than the maximum",
    [k.UNDER_LAMINATED_PANELS]: "under laminated panels",
    [k.UNDER_THE_SPOUT]: "under the spout",
    [k.UNDER_SPC_VINYL_PANELS]: "under SPC vinyl panels",
    [k.PRODUCT_TAILORED_TO_YOUR_SYSTEM]: "product tailored to your system",
    [k.YEARS_WARRANTY]: "years warranty",
    [k.THERMOREGULATOR]: "Thermoregulator",
    [k.INTERNAL_AIR_SENSOR]: "Internal air sensor",
    [k.EXTERNAL_AIR_SENSOR]: "External air sensor",
    [k.CONNECTION]: "Connection",
    [k.HAS]: "Has",
    [k.DOES_NOT_HAVE]: "Does not have",
    [k.WHITE]: "White",
    [k.BLACK]: "Black",
    [k.MANUAL]: "Manual",
    [k.INFO_ZIGBEE]: "Info: *ZigBee 3.0 control panel necessary for the operation of the system will be added to the project. Supports up to 50 devices.",
    [k.SKIP]: "Skip",
    [k.ATTENTION]: "Attention!",
    [k.ERROR]: "Error",
    [k.INFO]: "Info",
    [k.DO_YOU_WANT_TO_ADD_ANOTHER_ELEMERNTS]: "Do you want to add another elements of fixed construction?",
    [k.RECTANGLE]: "Rectangle",
    [k.LINE]: "Line",
    [k.ADDITIONAL_DISCOUNT_HAS_BEEN_CHARGED]: "An additional discount has been charged!",
    [k.IRREGULAR_SHAPE]: "Irregular shape",
    [k.VAPOR_BARRIER_FILM]: "Vapor barrier film",
    [k.INSULATING_UNDERLAY]: "Insulating underlay",
    [k.WIRES_BROWN]: "Wires - brown",
    [k.WIRES_BLUE]: "Wires - blue",
    [k.CONNECTORS_FOR_HEATING_FOIL]: "Connectors for heating foil (type A or B)",
    [k.SELF_FUSING_TAPE]: "Self-fusing tape",
    [k.HEAT_DECOR_ADHESIVE_TAPE]: "Heat Decor Adhesive Tape",
    [k.ARRANGEMENT]: "Arrangement",
    [k.DRAG]: "Drag",
    [k.DELETE_ROOM]: "Delete room",
    [k.INCORRECT_FORMAT]: "Incorrect format",
    [k.USE_HAND]: "Move the view",
    [k.THERE_IS_NO_SUCH_OFFER]: "There is no such offer",
    [k.FILE_TYPE_MUST_BE]: "File type must be image/jpeg, image/png, .pdf, image/gif, .dwg",
    [k.COPY_DATA]: "Copy data",
    [k.DELETE_THERMOSTAT]: `Delete thermostat`,
    [k.SHOWER_BATH__WASHING_MACHINE]: `Shower/bath/washing machine`,
    [k.FREESTANDING_WASHBASIN__FREESTANDING_BOWL]: `Freestanding washbasin/freestanding bowl`,
    [k.WALL_HUNG_WASHBASIN__HUNG_BOWL]: `Wall-hung washbasin/hung-bowl`,
    [k.SEWAGE_GRATE]: `Sewage grate`,
    [k.SELECT_POINTS_TO_CREATE_A_FIGURE]: `Select points to create a figure`,
};
