import { queryCache } from "react-query";
import CryptoAES from "crypto-js/aes";
import i18n from "i18next";
import k from "../i18n/keys";
const localStorageKey = "__auth_token__";
const localStorageEmail = "_email_";
const localStoragePass = "_password_";
const encryptKey = "ewfWE@#%$rf%chy3";

async function getToken() {
  return window.localStorage.getItem(localStorageKey);
}

function handleUserResponse(user) {
  window.localStorage.setItem(localStorageKey, user.token);
  return user;
}

function rememberEmailAndPass(email, password, remember) {
  if (remember === true) {
    const pass = CryptoAES.encrypt(password, encryptKey);
    window.localStorage.setItem(localStorageEmail, email);
    window.localStorage.setItem(localStoragePass, pass);
  }
}

async function login({ email, password, remember }) {
  return await client("User/Login", { email, password }).then(
    handleUserResponse,
    rememberEmailAndPass(email, password, remember)
  );
}

async function register({
  users
}) {
  return await client(
    "User/Register",
    { users }
  ).then(handleUserResponse);
}

async function forgotPass({ email }) {
  return await client("User/ForgotPass", { email });
}

async function resetPass({ password, confirmPassword, token }) {
  return await client("User/ResetPass", { password, confirmPassword }, token);
}

async function logout() {
  window.localStorage.removeItem(localStorageKey);
}

async function client(endpoint, data, token) {
  const config = {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      Authorization: token ? `Bearer ${token}` : undefined,
      "Content-Type": "application/json",
    },
  };

  const authURL = process.env.REACT_APP_API_URL;

  return window
    .fetch(`${authURL}/${endpoint}`, config)
    .then(async (response) => {
      if (response.status === 401) {
        return Promise.reject({message: i18n.t(k.WRONGLOGINORPASSWORD)});
      }
      if (response.status === 404) {
        return Promise.reject({message: i18n.t(k.MISSINGUSER)});
      }
      if (response.ok) {
        if (response.status !== 204) {
          return await response.json();
        }
      } else {
        const data = await response.json();
        if (data.errors) {
          return Promise.reject({ ...data.errors });
        } else return Promise.reject([]);
      }
    });
}

export {
  getToken,
  login,
  register,
  logout,
  localStorageKey,
  forgotPass,
  localStorageEmail,
  localStoragePass,
  resetPass,
};
