import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import k from "../i18n/keys";

const ProgressDotsMainWrapper = styled.div`
    display: flex;
    width: auto;
    justify-self: end;
    align-self: end;
    flex-direction: column;
`;
const ProgressDotsWrapper = styled.div`
    display: flex;
    justify-content: end;
`;
const ProgressDot = styled.div`
    width: 16px;
    height: 16px;
    margin: 3.5px;
    background-color: ${({ active }) => (active ? "#1DA263" : "#fff")};
    border: ${({ active }) => (active ? "" : "1px solid #C5C5C5")};
    border-radius: 16px;
`;
const ProgressDotInside = styled.div`
    width: 6px;
    height: 6px;
    margin: 5px;
    background-color: #fff;
    border-radius: 16px;
`;
const ProgressDescriptionWrapper = styled.div`
    color: ${({ theme: { colors } }) => colors.black};
    align-self: center;
    white-space: nowrap;
`;

export const ProgressDots = ({ activeTab, stepsCount, style }) => {
    const ShowProgressDots = () => {
        const dots = [];
        for (let i = 1; i < stepsCount + 1; i++) {
            dots.push(
                <ProgressDot key={i} active={i <= activeTab ? true : false}>
                    <ProgressDotInside />
                </ProgressDot>
            );
        }
        return dots;
    };

    return (
        <ProgressDotsMainWrapper style={{ ...style }}>
            <ProgressDescriptionWrapper>{i18n.t(k.STEP)}:</ProgressDescriptionWrapper>
            <ProgressDotsWrapper>
                <ShowProgressDots />
            </ProgressDotsWrapper>
        </ProgressDotsMainWrapper>
    );
};
